$(document).ready(function () {
    var IE_NAME = 'drop'; // IE - Insurance Effect
    var IE_DURATION = 400;
    var allowNext = true;

    $('.working-time-at-least input[type="radio"]').on('click', function () {
        $(this).closest('.section').find('.seasonally-employed').toggle();
    });

    $('.insuranse-option-selector').on('click', function () {
        var $this = $(this);
        var $customizeBody = $('[data-type="customize-coverage"]').closest('.insurance-option').find('.insurance-body');

        $("input.single-insurance-coverage").parent().removeClass("is-checked");
        $("input.single-insurance-coverage").prop("checked", false);

        if ($this.attr('data-type') !== 'customize-coverage') {
            $customizeBody.hide();
            selFullCoveragePrice($this);
        } else {
            $customizeBody.show();
            processAllTotalMonthly();
        }
        $('.more-about-insurance-options-note').addClass('hidden');
    });

    $('.insurance-plan-manage-btn').on('click', function () {
        clearSelectedOptions();
    });

    $('.monthly-insurance-coverage').on('click', processAllTotalMonthly);

    $('#single_plan_btn').on('click', function () {
        toggleInsuranceOption('single_plan_btn', 'single_plan_arr');
    });

    $("#single_plan_arr").on('click', function () {
        toggleInsuranceOption('single_plan_btn', 'single_plan_arr');
    });

    $('#monthly_plan_btn').on('click', function () {
        toggleInsuranceOption('monthly_plan_btn', 'monthly_plan_arr');
    });

    $("#monthly_plan_arr").on('click', function () {
        toggleInsuranceOption('monthly_plan_btn', 'monthly_plan_arr');
    });

    $(".single_plan_arr_details").on('click', function () {
        var $arrow = $(this);
        var $planBody = $arrow.next('.applicant-coverage');

        if ($arrow.hasClass('insurance-arr-down')) {
            $planBody.find('.coverage-item').slideToggle(200);
            $arrow.removeClass('insurance-arr-down');
        }
        else {
            $planBody.find('.coverage-item').slideToggle(200);
            $arrow.addClass('insurance-arr-down');
        }
    });

    $(".single_plan_name_details").on('click', function () {
        var $label = $(this);
        var $planBody = $label.parent().parent();
        var $arrow = $planBody.prev('.single_plan_arr_details');


        if ($arrow.hasClass('insurance-arr-down')) {
            $planBody.find('.coverage-item').slideToggle(200);
            $arrow.removeClass('insurance-arr-down');
        }
        else {
            $planBody.find('.coverage-item').slideToggle(200);
            $arrow.addClass('insurance-arr-down');
        }
    });

    $(".monthly_plan_arr_details").on('click', function () {
        var $arrow = $(this);
        var $planBody = $arrow.next('.applicant-coverage');

        if ($arrow.hasClass('insurance-arr-down')) {
            $planBody.find('.coverage-item').slideToggle(200);
            $planBody.find('.customize-question').removeClass('hidden');
            $arrow.removeClass('insurance-arr-down');
        }
        else {
            $planBody.find('.coverage-item').slideToggle(200);
            $planBody.find('.customize-question').addClass('hidden');
            $arrow.addClass('insurance-arr-down');
        }
    });

    $(".monthly_plan_name_details").on('click', function () {
        var $label = $(this);
        var $planBody = $label.parent().parent();
        var $arrow = $planBody.prev('.monthly_plan_arr_details');


        if ($arrow.hasClass('insurance-arr-down')) {
            $planBody.find('.coverage-item').slideToggle(200);
            $planBody.find('.customize-question').removeClass('hidden');
            $arrow.removeClass('insurance-arr-down');
        }
        else {
            $planBody.find('.coverage-item').slideToggle(200);
            $planBody.find('.customize-question').addClass('hidden');
            $arrow.addClass('insurance-arr-down');
        }
    });

    function toggleInsuranceOption(buttonId, arrowId) {
        var $arrow = $('#' + arrowId);
        var $linkedButton = $('#' + buttonId);
        var baseText = $linkedButton.attr('data-base-text');
        var autoChecked = $linkedButton.attr('data-auto-checked');
        var $planBody = $linkedButton.closest('.insurance-plan-body');

        if (autoChecked && !$planBody.find('.insurance-plan-options').is(':visible')) {
            $planBody.find(autoChecked).prop('checked', true);
        }

        if ($linkedButton.text().trim() !== baseText) {
            $planBody.find('.insurance-plan-options').slideToggle(400, function () {
                $linkedButton.slideToggle(200);
                $arrow.addClass('insurance-arr-down');
                $linkedButton.text($linkedButton.text().trim() === baseText ? 'Close' : baseText);
            });
        }
        else {
            $linkedButton.slideToggle(200, function () {
                $planBody.find('.insurance-plan-options').slideToggle(400);
                $arrow.removeClass('insurance-arr-down');
                $linkedButton.text($linkedButton.text().trim() === baseText ? 'Close' : baseText);
            });
        }
    }

    hideUnselectedOptions();

    function hideUnselectedOptions() {
        $('.insurance-option').each(function () {
            var $item = $(this);

            if ($item.attr('data-expand') === 'false') {
                $item.find('.insurance-body').hide();
            }
        });
    }

    function selFullCoveragePrice($this) {
        $('.total-monthly-sum').text($this.closest(".insurance-option").find(".lb-cost-sum").text().substring(1));
    }

    $('.insuranse-option-selector:checked').each(function () {
        if ($(this).attr('data-type') !== 'customize-coverage') {
            selFullCoveragePrice($(this));
        } else {
            processAllTotalMonthly();
        }
    });
    if ($('#monthlyPremium').attr('data-content') === 'FullCoverage' || $('#monthlyPremium').attr('data-content') === 'CustomizeCoverage') {
        $('#monthlyPremium').siblings('.insurance-plan-body').find('.insurance-plan-options').slideToggle(400);
    }

    $(".single-insurance-coverage").change(function () {
        $("input.monthly-insurance-coverage").parent().removeClass("is-checked");

        var $customizeBody = $('[data-type="customize-coverage"]').closest('.insurance-option').find('.insurance-body');
        $customizeBody.hide("slow");

        $('[data-type="customize-coverage"]').parent().removeClass("is-checked");
        $('[data-type="full-coverage"]').parent().removeClass("is-checked");

        allowNext = false;

        $('.selected-option-more-information').removeClass("is-checked");
        $('.selected-option-no-insurance').removeClass("is-checked");

        validateSinglePremiumOptions(false);
    });

    $(".errorMessage").change(function () {
        var val = $("errorMessage").val();
        if (val !== null && val !== "") {
            showNotification(val, notificationType.Error);
        }
    });

    $('#SelectedOption_Single').parent().find('input[type="checkbox"]').change(function () {
        $('#SelectedOption_Single').prop("checked", true);
    });

    $('.selected-option-more-information').on('click', function (evt) {
        clearSelectedOptions();

        $('.more-about-insurance-options-note').removeClass('hidden');
    });

    $('.selected-option-no-insurance').on('click', function (evt) {
        clearSelectedOptions();
    });

    function clearSelectedOptions() {
        $("input.monthly-insurance-coverage").parent().removeClass("is-checked");

        var $customizeBody = $('[data-type="customize-coverage"]').closest('.insurance-option').find('.insurance-body');
        $customizeBody.hide("slow");

        $('[data-type="customize-coverage"]').parent().removeClass("is-checked");
        $('[data-type="full-coverage"]').parent().removeClass("is-checked");

        $("input.single-insurance-coverage").parent().removeClass("is-checked");
      
        $('.more-about-insurance-options-note').addClass('hidden');
    }

    function processAllTotalMonthly() {
        var $insurancePlanOptions = this !== window ? $(this).closest('.insurance-plan-options') : $('.insurance-plan-options');

        $insurancePlanOptions.each(function () {
            var $insurancePlanOption = $(this);
            var $applicantCoverages = $insurancePlanOption.find('.applicant-coverage');
            var $totalMonthly = $insurancePlanOption.find('.total-monthly');
            var applicantCoveragesTotalSum = 0.0;

            for (var i = 0; i < $applicantCoverages.length; i++) {
                var $applicantCoverage = $($applicantCoverages[i]);
                var applicantCoverageSum = 0;

                $applicantCoverage.find('.coverage-selector:checked').each(function () {
                    applicantCoverageSum += parseFloat($(this).attr('data-price'));
                });

                applicantCoveragesTotalSum += applicantCoverageSum;
            }

            $totalMonthly.find('.total-monthly-sum').text(applicantCoveragesTotalSum.toFixed(2));
        });
    }

    function calculateSingleSummary() {
        var loanAmount = parseFloat($('.requote-loan-amount').attr('data-original-value') || 0);
        var estimatedPayment = parseFloat($('.requote-updated-estimated-payment').attr('data-original-value') || 0);
        var monthlyFactor = parseFloat($('#MonthlyFactor').val() || 0);
        var selectedBenefitSum = 0;
        var selectedMonthlyCost = 0;
        $('.single-insurance-coverage:checked').each(function (item) {
            var $this = $(this);
            selectedBenefitSum += parseFloat($this.attr('data-price'));
            selectedMonthlyCost += parseFloat($this.attr('data-monthly-payment'));
        });

        $('.requote-loan-amount').empty();
        $('.requote-loan-amount').append("<h2>$" + (loanAmount + selectedBenefitSum).toFixed(2) + "</h2>");

        $('.requote-updated-estimated-payment').empty();
        $('.requote-updated-estimated-payment').append("<h2>$" + (estimatedPayment + (monthlyFactor * selectedMonthlyCost)).toFixed(2) + "</h2>");
    }

    calculateSingleSummary();

    $('button[value="Next"]').on('click', function (evt) {
        if (!allowNext && !$('.SelectedOption_Full').is(':checked') && !$('.SelectedOption_Customize').is(':checked')) {
            $('.calculate-premium-message').show();
            evt.preventDefault();
            return false;
        }
    });

    $(".actively-working-yes").on('click', function (event) {
        event.stopPropagation();

        var $check = $(this);
        $check.parent().next(".seasonally-employed").toggleClass("hidden", true);
        $check.parent().next().next(".seasonally-employed").toggleClass("hidden", true);

        if ($(".is-smoker-yes").hasClass('is-checked') || $(".is-smoker-no").hasClass('is-checked')) {
            activateFormButton();
        }
    });

    $(".actively-working-no").on('click', function (event) {
        event.stopPropagation();

        var $check = $(this);
        $check.parent().next(".seasonally-employed").toggleClass("hidden", false);
        $check.parent().next().next(".seasonally-employed").toggleClass("hidden", false);

        if ($(".is-smoker-yes").hasClass('is-checked') || $(".is-smoker-no").hasClass('is-checked')) {
            if ($(".seasonally-employed-yes").hasClass('is-checked') || $(".seasonally-employed-no").hasClass('is-checked')) {
                activateFormButton();
            }
            else {
                deactivateFormButton();
            }
        }
    });

    $(".seasonally-employed-yes").on('click', function (event) {
        event.stopPropagation();

        if ($(".actively-working-yes").hasClass('is-checked') || $(".actively-working-no").hasClass('is-checked')) {
            if ($(".is-smoker-yes").hasClass('is-checked') || $(".is-smoker-no").hasClass('is-checked')) {
                activateFormButton();
            }
        }
    });
    $(".seasonally-employed-no").on('click', function (event) {
        event.stopPropagation();

        if ($(".actively-working-yes").hasClass('is-checked') || $(".actively-working-no").hasClass('is-checked')) {
            if ($(".is-smoker-yes").hasClass('is-checked') || $(".is-smoker-no").hasClass('is-checked')) {
                activateFormButton();
            }
        }
    });

    $(".is-smoker-yes").on('click', function (event) {
        event.stopPropagation();

        if ($(".actively-working-yes").hasClass('is-checked') || $(".actively-working-no").hasClass('is-checked')) {
            if ($(".actively-working-no").hasClass('is-checked')) {
                if ($(".seasonally-employed-yes").hasClass('is-checked') || $(".seasonally-employed-no").hasClass('is-checked')) {
                    activateFormButton();
                }
            }
            else {
                activateFormButton();
            }
        }
    });
    $(".is-smoker-no").on('click', function (event) {
        event.stopPropagation();

        if ($(".actively-working-yes").hasClass('is-checked') || $(".actively-working-no").hasClass('is-checked')) {
            if ($(".actively-working-no").hasClass('is-checked')) {
                if ($(".seasonally-employed-yes").hasClass('is-checked') || $(".seasonally-employed-no").hasClass('is-checked')) {
                    activateFormButton();
                }
            }
            else {
                activateFormButton();
            }
        }
    });

    $(".add-beneficiary").on('click', function (event) {
        event.stopPropagation();

        var $container = $(this);

        $container.slideToggle(200, function () {
            $container.toggleClass("hidden", true);
        });

        $container.next().toggleClass("hidden", false);
        $container.next().find('.beneficiary-entry-summary').slideToggle(400);
    });

    $(".beneficiary-description-header").on("click", function () {
        if ($(this).closest(".beneficiary-entry-container").find(".beneficiary-entry-summary").css("display") === "none") {
            $(this).closest(".beneficiary-entry-container").find(".beneficiary-entry-title").find(".beneficiary-entry-arrow i").text("keyboard_arrow_up");
            $(this).closest(".beneficiary-entry-container").find(".beneficiary-entry-summary").show("slow");
        }
        else {
            $(this).closest(".beneficiary-entry-container").find(".beneficiary-entry-title").find(".beneficiary-entry-arrow i").text("keyboard_arrow_down");
            $(this).closest(".beneficiary-entry-container").find(".beneficiary-entry-summary").hide("slow");
        }
    });

    $(".beneficiary-arrow").on("click", function () {
        if ($(this).parent().parent().next(".beneficiary-entry-summary").css("display") === "none") {
            $(this).text("keyboard_arrow_up");
            $(this).parent().parent().next(".beneficiary-entry-summary").show("slow");
        }
        else {
            $(this).text("keyboard_arrow_down");
            $(this).parent().parent().next(".beneficiary-entry-summary").hide("slow");
        }
    });

    $("#insurance-questions-next-button").on("click", function () {
        $(".init-mode-section").toggleClass("hidden", true);
        $(".post-mode-section").toggleClass("hidden", false);
        $(".post-mode-section").show("slow");

        //deactivateFormButton();
    });

    $(".is-interested-in-coverage-yes").on('click', function () {
        activateFormButton();
    });

    $(".is-interested-in-coverage-no").on('click', function () {
        activateFormButton();
    });

    function activateFormButton() {
        var $button = $(".btn-deactivated");

        $button.removeClass('btn-deactivated');
        $button.prop("disabled", false);

        $(".mob_btn_right").removeClass('mob_btn_disabled');
    }

    function deactivateFormButton() {
        var $button = $(".next");

        $button.addClass('btn-deactivated');
        $button.prop("disabled", true);
    }

    $('.SelectedOption_Full, .SelectedOption_Customize').on('click', function () {
        validateSinglePremiumOptions(true);
    });

    function validateSinglePremiumOptions(isValid) {
        if (isValid === true) {
            $('.calculate-premium-message').hide();
        }
        else {
            $('.calculate-premium-message').show();
        }

        $('button[value="Next"]').prop("disabled", !isValid);
    }
});
