var inbranch = window.InBranchApplication;

function resetSessionTimeout() {
    fetch('/application/SessionSetup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.json())
        .then(data => {
           
        });
}

// Set up event listeners to detect user activity
if (inbranch == 'False') {
    $(document).ready(function () {
        resetSessionTimeout();
    });
}