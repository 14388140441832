$(function () {
    $('.product-info-tooltip').tooltip({ html: true, placement: 'left' });
    $('.js-tooltip').tooltip();

    $('.footer_aside_open').on('click', function () {
        var $this = $(this); 
        var togglePopup = function (state) {
            $this.toggleClass('footer_aside_show', state);
            $this.next('.footer_aside_wrap').toggleClass('footer_aside_show', state);
        };
        togglePopup();
        setTimeout(function(){
            if($this.is(".footer_aside_show")) {
                var closePopup = function (e) {
                    if($(e.target).closest(".footer_aside_wrap").length === 0) {
                        togglePopup(false);
                        $("body").off("click", closePopup);
                    }
                };
                $("body").on("click", closePopup);
            }
        });
       
    });

    $(".prod_item").hover(
        function () {
            $('.prod_item').removeClass('prod_item_selected');
            $('.bwb_prod_img').css('opacity', '0.5');
            $(this).find('.bwb_prod_img').css('opacity', '1');
            $(this).addClass('prod_item_selected');

            var content = $(this).find('.prod_item_info').html();
            $('.prod_selected_category_content').html("").removeClass('animated slideInUp');
            setTimeout(function() {
                $('.prod_selected_category_content').html(content).addClass('animated slideInUp');
            });
        }, function () {
            $(this).addClass('default-product-category');
            $('.prod_item').addClass('prod_item_selected');
            $('.bwb_prod_img').css('opacity', '1');
        }
    );

    // mob footer popup
    $('.footer_navClick').on('click', function () {
        $(this).next('.footerPopup').show(300);
    });

    $('.footer_navClose').on('click', function () {
        $(this).parent('.footerPopup').hide(300);
    });

    $('.prod_wrap').hover(
        function () {
            $(this).find('.prod_item_inner').css('opacity', '0.5');
        }, function () {
            $(this).find('.prod_item_inner').css('opacity', '1');
        }
    );

    $(".mob_aside_info .ico_open").on('click', function() {
        $(this).parent('.prod_item').find('.prod_item_info_header').stop(true, true).slideToggle(300);
        $(this).parent('.prod_item').toggleClass('prod_item_active');
    });
    
    $('.bwb-list-item-block').on('click', function () {
        $(this).find('.prod_item_info').stop(true, true).slideToggle(300);
        $(this).toggleClass('prod_item_active');

        if ($(this).find('.ico_open').text() === 'expand_more') {
            $(this).find('.ico_open').html('expand_less');
            $(this).find('.prod_list_info').find('img').show();
        }
        else {
            $(this).find('.ico_open').html('expand_more');
        }
    });

    // scrollbar
    $('.scrollbar-inner').scrollbar();

    $(".mdl-menu__container").attr("tabindex", "-1");

});
