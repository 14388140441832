$(document).ready(function () {
    $('[name=ApplicationId]').keyup(function () {
        var id = $(this).val().replace('-', '');
        if (id && id.length > 2) {
            var idLength = id.length;
            $(this).val(id.substring(0, idLength - 2) + '-' + id.substring(idLength - 2, idLength));
        }
    });
    // preload
    $('.mob_btn_center.page').hide();

    $.fn.datepicker.Constructor.prototype.place = function () {
        if (this.isInline)
            return this;
        var calendarWidth = this.picker.outerWidth(),
            calendarHeight = this.picker.outerHeight(),
            visualPadding = 10,
            container = $(this.o.container),
            windowWidth = container.width(),
            scrollTop = this.o.container === 'body' ? $(document).scrollTop() : container.scrollTop(),
            appendOffset = container.offset();

        var parentsZindex = [0];
        this.element.parents().each(function () {
            var itemZIndex = $(this).css('z-index');
            if (itemZIndex !== 'auto' && Number(itemZIndex) !== 0) parentsZindex.push(Number(itemZIndex));
        });
        var zIndex = Math.max.apply(Math, parentsZindex) + this.o.zIndexOffset;
        var offset = this.component ? this.component.parent().offset() : this.element.offset();
        var height = this.component ? this.component.parent().outerHeight(true) : this.element.outerHeight(false);
        var width = this.component ? this.component.parent().outerWidth(true) : this.element.outerWidth(false);
        var left = offset.left - appendOffset.left;
        var top = offset.top - appendOffset.top;

        if (this.o.container !== 'body') {
            top += scrollTop;
        }

        this.picker.removeClass(
            'datepicker-orient-top datepicker-orient-bottom ' +
            'datepicker-orient-right datepicker-orient-left'
        );

        if (this.o.orientation.x !== 'auto') {
            this.picker.addClass('datepicker-orient-' + this.o.orientation.x);
            if (this.o.orientation.x === 'right')
                left -= calendarWidth - width;
        }
        else {
            if (offset.left < 0) {
                this.picker.addClass('datepicker-orient-left');
                left -= offset.left - visualPadding;
            } else if (left + calendarWidth > windowWidth) {
                this.picker.addClass('datepicker-orient-right');
                left += width - calendarWidth;
            } else {
                if (this.o.rtl) {
                    this.picker.addClass('datepicker-orient-right');
                } else {
                    this.picker.addClass('datepicker-orient-left');
                }
            }
        }

        var yorient = this.o.orientation.y,
            top_overflow;
        if (yorient === 'auto') {
            top_overflow = -scrollTop + top - calendarHeight;
            yorient = top_overflow < 0 ? 'bottom' : 'top';
        }

        this.picker.addClass('datepicker-orient-' + yorient);
        if (yorient === 'top')
            top -= calendarHeight + parseInt(this.picker.css('padding-top'));
        else
            top += height;

        if (this.o.rtl) {
            var right = windowWidth - (left + width);
            this.picker.css({
                top: top,
                right: right,
                zIndex: zIndex
            });
        } else {
            this.picker.css({
                top: top,
                left: left,
                zIndex: zIndex
            });
        }
        return this;
    }

    $(".datepicker-box").datepicker({
        container: "#mainContainer",
        orientation: "right",
    }).on('changeDate', function (e) {
        $(this).addClass('is-dirty');
    });

    $('.closing-date-datepicker').datepicker('setStartDate', new Date());

    $('.onchange-visibillity-trigger').each(function (item) {
        var $this = $(this);
        var $subscribeItem = $this.find($this.attr('data-subscribe-event'));
        var $container = $this.closest($this.attr('data-container'));
        var target = $this.attr('data-target');
        var visibleValue = $this.attr('data-visible-value');

        $subscribeItem.on('change', onChangeTriggerHandler);

        function onChangeTriggerHandler() {
            var triggerVisibility = function() {
                $(window).trigger("visibilityChanged", {
                    target: target
                });
            };
            if ($(this).val() == visibleValue) {
                $container.find(target).slideDown(triggerVisibility);
            } else {
                $container.find(target).prop("checked", false);
                $container.find(target).slideUp(triggerVisibility);
            }            
        }
    });

    $('button[type="button"][data-redirect-to]').on('click', function () {
        var $this = $(this);
        var _target = $this.attr('data-target-window');
        var _location = $(this).attr('data-redirect-to');

        if (_target) {
            window.open(_location, _target);
        } else {
            window.location = _location;
        }
    });

    $('.mob_btn_left').on('click', function () {
        $('button.back').click();
    });

    $('.mob_btn_right').on('click', function () {
        $('button.next, #cumis-insurance-next-btn').click();
    });

    turnOnOffMobileButtons();

    $('.need-help').on('click', function () { $('.need-help-info').slideToggle(); });

    $('.help-info-item').hover(
        function () { $('.help-info-item').not(this).find('.need-help-item-text').hide(); },
        function () { $('.help-info-item').find('.need-help-item-text').show(); });
    
    var currentStep = $(".steps .selected_list");
    if (currentStep && currentStep.length === 1) {
        var offsetTop = currentStep[0].offsetTop;
        setTimeout(function() {
            $(".steps .scrollbar-inner").scrollTop(offsetTop - $(".steps").height()/2);
        });
    }

    if (Is_iOS()) {
        $(".mdl-js-ripple-effect").removeClass("mdl-js-ripple-effect");
    }
});

var localStorageImportantKey = "#important";

function removeReadonlyAttr(obj) {
    if (obj.hasAttribute('readonly')) {
        obj.removeAttribute('readonly');
        // fix for mobile safari to show virtual keyboard
        obj.blur();
        obj.focus();
    }
}

function turnOnOffMobileButtons() {
    //Mob back button
    var backBtn = $('button.back');
    if (backBtn.length > 0) {
        if (backBtn.prop('disabled'))
            $('.mob_btn_left').addClass('mob_btn_disabled');
        else
            $('.mob_btn_left').removeClass('mob_btn_disabled');
    }    

    var persBackBtn = $('button.back-personal-info-js');
    if (persBackBtn.length > 0) {
        $('.mob_btn_left').addClass('hidden');
    }

    //Mob next button
    var nextBtn = $('button.next');
    if (nextBtn.length > 0) {
        if (nextBtn.prop('disabled'))
            $('.mob_btn_right').addClass('mob_btn_disabled');
        else
            $('.mob_btn_right').removeClass('mob_btn_disabled');
    }    
}

function scrollIntoView(id) {
    try {
        if (id != null && id !== "") {
            window.location.hash = id;
        }
    } catch (e) {

    }
}

function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, "\\$&");

    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function uploadFileViaAjax(url, data, sucessCallback) {
    $.ajax({
        type: "POST",
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        url: url,
        success: function (data) {
            sucessCallback(data);
        },
        async: true,
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
    });
}

var uploadControl = function () {
    function init(selector, _beforeUploadCallback) {
        var beforeUploadCallback = _beforeUploadCallback;
        $(selector || ".upload-control").each(function () {
            var $sender = $(this);
            var $button = $sender.find("button");
            var $upload = $sender.find("input[type=file]");
           
            $upload.change(function () {
                if (beforeUploadCallback) {
                    beforeUploadCallback($sender);
                }

                $button.click();
            });
        });
    }
    return {
        init: init
    };
}();

function Is_iOS() {
    return !!navigator.platform.match(/iPhone|iPod|iPad|MacIntel/);
}

function clearLocalStorage(removeImportant)
{
    if (removeImportant)
    {
        localStorage.clear();

        return;
    }

    var importantItems = {};
    Object.keys(localStorage).forEach(function(key){
        if (key.includes(localStorageImportantKey)) {
            importantItems[key] = localStorage.getItem(key);
        }
    });

    localStorage.clear();
    Object.keys(importantItems).forEach(function (key) {
        localStorage.setItem(key, importantItems[key]);
    });
}   