function setupAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    try {
        if (!isGoogle) {
            var fields = [
                { element: lookupField, field: "FormattedAddress", mode: pca.fieldMode.DEFAULT },
                { element: suiteNumber, field: "SubBuilding", mode: pca.fieldMode.POPULATE },
                { element: streetNumber, field: "BuildingNumber", mode: pca.fieldMode.POPULATE },
                { element: streetName, field: "Street", mode: pca.fieldMode.POPULATE },
                { element: city, field: "City", mode: pca.fieldMode.POPULATE },
                { element: province, field: "ProvinceCode", mode: pca.fieldMode.POPULATE },
                { element: postalCode, field: "PostalCode", mode: pca.fieldMode.POPULATE }
            ];
            var control;
            if (lookupField === "primaryAddressLookup"
                || lookupField === "alternateAddressLookup"
                || lookupField === "withsuccessorAddressLookup") {

                control = new pca.Address(fields, { id: lookupField, key: key });
                control.listen("populate", function (address) {
                    $("input[id='" + province + "']").closest(".mdl-select").data("mdl-select").setValue(address.ProvinceCode);
                    if (address.POBoxNumber) {
                        $("input[id='" + streetNumber + "']").val(address.POBoxNumber);
                        var streetNameValue = address.Line1.match(/Stn.*/);
                        $("input[id='" + streetName + "']").val(streetNameValue !== null ? streetNameValue[0] : "");
                        $("input[id='" + streetName + "']").closest(".offline-address").find("input[name='IsPOBox'][value='True']").click();
                    }
                    else {
                        $("input[id='" + streetName + "']").closest(".offline-address").find("input[name='IsPOBox'][value='False']").click();
                    }
                });
            }
            else {
                control = new pca.Address(fields, { name: lookupField, key: key });
                control.listen("populate", function (address) {
                    if (address.POBoxNumber) {
                        $("input[name='" + streetNumber + "']").val(address.POBoxNumber);
                        var streetNameValue = address.Line1.match(/Stn.*/);
                        $("input[name='" + streetName + "']").val(streetNameValue !== null ? streetNameValue[0] : "");
                    }
                    $("input[name='" + province + "']").closest(".mdl-select").data("mdl-select").setValue(address.ProvinceCode);
                });
            }
            var updatePosition = function () {
                control.autocomplete.reposition();
                control.countrylist.autocomplete.reposition()
            };
            control.listen("focus", function () {
                $(".scrollbar-inner").on("scroll", updatePosition);
            });
            control.listen("blur", function () {
                $(".scrollbar-inner").off("scroll", updatePosition);
            });
            return control;
        } else
        {
            setupGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle);
            
            }

             


        
    } catch (e) {
    }
    return null;
}

function addressCompleteHandler(control, callback) {
    try {
        control.listen("populate", callback);
        control.listen("error", callback);
    } catch (e) {
    }
}

(function autoInitAddressCompleteControls() {
    $(".address-auto-complete").each(function () {
        var $container = $(this),
            key = $("input[data-field=AddressCompleteKey]", $container).val(),
            addressCompleteEnabled = $("input[data-field=AddressCompleteEnabled]", $container).val(),
            lookupField = $("input[data-field=AddressLookup]", $container).attr("name"),
            suiteNumber = $("input[data-field=SuiteNumber]", $container).attr("name"),
            streetNumber = $("input[data-field=StreetNumber]", $container).attr("name"),
            streetName = $("input[data-field=StreetAddress]", $container).attr("name"),
            city = $("input[data-field=City]", $container).attr("name"),
            province = $(":input[data-field=Province]", $container).attr("name"),
            postalCode = $("input[data-field=Postal]", $container).attr("name"),
            isManual = $("input[data-trigger=manualAddress]:checked", $container).val();

        if (addressCompleteEnabled && !isManual) {
            var control = setupAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode,false);
            addressCompleteHandler(control, function() {
                showAddressInformation($container);
                $("input[data-trigger=manualAddress]", $container).attr("checked", true);
                MaterialUtils.updateTextFields($container);
                $container.trigger("finished");
            });
            $("input[data-trigger=manualAddress]", $container).closest("label").on("click", function(){
                showAddressInformation($container);
            });
        } else {
            showAddressInformation($container);
        }
    });
    
    function showAddressInformation($container){
        $(".address-information", $container).removeClass("hidden");
        $(".address-lookup", $container).addClass("hidden");
    }
})();