var CustomValidator = {
    TextFieldTypes: ['text', 'number', 'email', 'tel'],
    GroupClass: 'form-group',
    OptionGroup: 'option-group',
    Containers: '',
    ValidationMessage: {
        DefaultText: 'Field is required.',
        CustomTextByType: [
            {
                Type: 'radio',
                Value: 'This question is mandatory.'
            }
        ],
        Classes: ['field-validation-error', "text-danger", "title_h7", "text-danger", "m-0", "d-block"],
        UniqueCustomClass: 'custom-validator--message',
        HtmlElement: 'span'
    },
    OnBegin: function (containers) {
        if (!$.isArray(containers)) {
            containers = [containers];
        }

        var messages = containers.reduce(function (acc, container) {
            var inputs = $('.' + CustomValidator.ValidationMessage.UniqueCustomClass, $(container));
            return acc.concat(inputs.toArray());
        }, []);


        for (var j = 0; j < messages.length; j++) {
            messages[j].parentNode.removeChild(messages[j]);
        }

        $('.lading_btn_wrap .products-validation-summary-message').remove();
    },
    OnInvalid: function () {
        var selector = '.' + CustomValidator.ValidationMessage.UniqueCustomClass;

        if (!$($(selector)[0]).is(':visible')) {
            $('.lading_btn_wrap').prepend($('<h6 class="text-center products-validation-summary-message text-danger">Please, fix all validation errors.</h6>'));
        }
        else {
            if ($(CustomValidator.Containers).hasClass('asset-entry-container') === true && GetIEVersion() > 0) { }
            else {
                setTimeout(function () {
                    $(selector)[0].scrollIntoView({
                        behavior: "smooth"
                    });
                }, 100);
            }
        }
    },
    Rule: null,
    Validate: function (containers) {
        CustomValidator.Containers = containers;

        CustomValidator.OnBegin(containers);

        var errorMessages = [];
        var privateFuncs = {
            groupFields: function (fields) {
                var res = [];

                for (var i = 0; i < fields.length; i++) {
                    var type = fields[i].getAttribute('type');
                    var key = fields[i].getAttribute('id');
                    var name = fields[i].getAttribute('name');
                    var value = fields[i].value;
                    var minVal = fields[i].getAttribute('minVal');
                    var maxVal = fields[i].getAttribute('maxVal');
                    var isFind = false;

                    if (name !== key) {
                        key = name;
                    }

                    for (var j = 0; j < res.length; j++) {
                        if (res[j].key === key) {
                            if (CustomValidator.TextFieldTypes.indexOf(type) > -1 || (type === 'radio' && fields[i].checked)) {
                                res[j].values.push(value);
                            }
                            isFind = true;
                        }
                    }

                    if (!isFind) {
                        var values = [];

                        if (CustomValidator.TextFieldTypes.indexOf(type) > -1 || (type === 'radio' && fields[i].checked)) {
                            values.push(value);
                        }

                        res.push({
                            key: key,
                            name: name,
                            values: values,
                            type: type,
                            minVal: minVal,
                            maxVal: maxVal,
                            errType: 1
                        });
                    }
                }

                return res;
            },
            getPlanType: function (fields) {
                var res = '';

                for (var i = 0; i < fields.length; i++) {
                    if (fields[i].name !== null && fields[i].name.indexOf("PlanType") > 0) {
                        res = fields[i].values[0];
                        break;
                    }
                }
                return res;
            },
            getParent: function (node) {
                if (node !== null) {
                    if (node.classList && node.classList.length > 0 &&
                        node.classList.contains(CustomValidator.GroupClass)) {
                        return node;
                    }

                    return this.getParent(node.parentNode);
                }

                return null;
            },
            createMessage: function (validationMessage) {
                var message = document.createElement(CustomValidator.ValidationMessage.HtmlElement);

                for (var j = 0; j < CustomValidator.ValidationMessage.Classes.length; j++) {
                    message.classList.add(CustomValidator.ValidationMessage.Classes[j]);
                }

                message.classList.add(CustomValidator.ValidationMessage.UniqueCustomClass);
                message.innerHTML = validationMessage;

                return message;
            },
            getValidationMessage: function (errorField) {
                return this.getCustomMessageByType(errorField) || CustomValidator.ValidationMessage.DefaultText;
            },
            showValidationMessages: function (errorFields) {
                for (var i = 0; i < errorFields.length; i++) {
                    var parent = document.querySelectorAll('[name="' + errorFields[i].name + '"]')[0].parentNode;
                    var message = this.createMessage(this.getValidationMessage(errorFields[i]));

                    parent = this.getParent(parent);

                    try {
                        parent.appendChild(message);
                        errorMessages.push(message);
                    }
                    catch (e) { }
                }
            },
            getCustomMessageByType: function (errorField) {
                var customMessage = null;

                for (var i = 0; i < CustomValidator.ValidationMessage.CustomTextByType.length; i++) {

                    if (errorField.name.indexOf('MortgageType') >= 0) {
                        customMessage = "mortgage type is required";
                    }
                    else if (errorField.name.indexOf('ValueOfHome') >= 0 && errorField.errType == 1) {
                        customMessage = "purchase price is required";
                    }
                    else if (errorField.name.indexOf('DownPayment') >= 0) {
                        customMessage = "down payment amount is required";
                    }
                    else if (errorField.name.indexOf('SwitchingFromAnotherInstitution') >= 0) {
                        customMessage = "this question is mandatory";
                    }
                    else if (errorField.name.indexOf('DesiredAmount') >= 0 && errorField.errType == 1) {
                        customMessage = "requested amount is mandatory";
                    }
                    else if ((errorField.name.indexOf('DesiredAmount') >= 0 || errorField.name.indexOf('ValueOfHome') >= 0) && errorField.errType == 2) {
                        customMessage = "value amount can't be less than $" + errorField.minVal;
                    }
                    else if ((errorField.name.indexOf('DesiredAmount') >= 0 || errorField.name.indexOf('ValueOfHome') >= 0) && errorField.errType == 3) {
                        customMessage = "value amount can't be more than $" + errorField.maxVal;
                    }
                    else if (errorField.name.indexOf('OutstandingMortgageBalance') >= 0) {
                        customMessage = "outstanding mortgage balance is required";
                    }
                    else if (errorField.name.indexOf('MarketValue') >= 0) {
                        customMessage = "market value is required";
                    }
                    else if (errorField.name.indexOf('SelectedTermId') >= 0) {
                        customMessage = "term is required";
                    }
                    else if (errorField.name.indexOf('SelectedAmortizationId') >= 0) {
                        customMessage = "amortization is required";
                    }
                    else if (errorField.name.indexOf('PaymentFrequency') >= 0) {
                        customMessage = "payment frequency is required";
                    }

                    if (customMessage !== null) {
                        break;
                    }

                    if (CustomValidator.ValidationMessage.CustomTextByType[i].Type == errorField.Type) {
                        customMessage = CustomValidator.ValidationMessage.CustomTextByType[i].Value;
                        break;
                    }
                }

                return customMessage;
            },
            isFunction: function (fn) {
                return fn && typeof fn === 'function';
            },
            hasOptional: function (field) {
                return $(field).closest('.' + CustomValidator.GroupClass).is('.' + CustomValidator.OptionGroup);
            },
            getValidationFields: function (fields) {
                var validationFields = [];

                for (var i = 0; i < fields.length; i++) {
                    if (!this.hasOptional(fields[i])) {
                        if (this.isFunction(CustomValidator.Rule)) {
                            if (CustomValidator.Rule(fields[i])) {
                                validationFields.push(fields[i]);
                            }
                        } else {
                            if ($(fields[i]).closest('.' + CustomValidator.GroupClass).css('display') !== 'none') {
                                validationFields.push(fields[i]);
                            }
                        }
                    }
                }

                return validationFields;
            },
            getValidatableSelects: function (container) {
                var selects = $(container).find(".mdl-select:visible .invisible-for-autocomplete");
                var validatableSelects = [];

                for (var i = 0; i < selects.length; i++) {
                    if (!$(selects[i]).closest("." + CustomValidator.GroupClass).is("." + CustomValidator.OptionGroup)) {
                        validatableSelects.push(selects[i]);
                    }
                }

                return validatableSelects;
            },
            validateInputAndRadio: function (errorFields, container) {
                var productFields = this.getValidationFields($(container).find('input:visible:not([type="hidden"])'));

                var productFieldsGroup = this.groupFields(productFields);
                var planType = this.getPlanType(productFieldsGroup);

                for (var j = 0; j < productFieldsGroup.length; j++) {
                    var field = productFieldsGroup[j];

                    if (CustomValidator.TextFieldTypes.indexOf(field.type) > -1 && (field.values.length === 0 || field.values[0].trim().length == 0)) {
                        if (planType === "Spousal" && (field.name.indexOf("ContributorFirstName") >= 0 || field.name.indexOf("ContributorLastName") >= 0 || field.name.indexOf("ContributorSin") >= 0)) {
                            var relatedNodeName = '';

                            if (field.name.indexOf('ContributorFirstName') >= 0) {
                                relatedNodeName = field.name.replace('ContributorFirstName', 'PlanType');
                            }
                            if (field.name.indexOf('ContributorLastName') >= 0) {
                                relatedNodeName = field.name.replace('ContributorLastName', 'PlanType');
                            }
                            if (field.name.indexOf('ContributorSin') >= 0) {
                                relatedNodeName = field.name.replace('ContributorSin', 'PlanType');
                            }

                            var parent = document.querySelectorAll('[name="' + relatedNodeName + '"]')[0].parentNode;

                            if (parent !== null && !$(parent).hasClass('is-checked')) {
                                errorFields.push(field);
                            }
                        }
                        else {
                            errorFields.push(field);
                        }
                    }

                    if (CustomValidator.TextFieldTypes.indexOf(field.type) > -1 && field.type === 'number' && field.values[0] !== '' ) {
                        if (parseInt(field.values[0]) < parseInt(field.minVal)) {
                            field.errType = 2;
                            field.minVal = field.minVal;
                            errorFields.push(field);
                        }
                        if (parseInt(field.values[0]) > parseInt(field.maxVal)) {
                            field.errType = 3;
                            field.maxVal = field.maxVal;
                            errorFields.push(field);
                        }
                    }

                    if (field.type === 'radio' && field.values.length === 0) {

                        if (field.name.indexOf('RSPExistingPlanFor') >= 0) {
                            var relatedNodeName = field.name.replace('RSPExistingPlanFor', 'RSPPreExisting');
                            var parent = document.querySelectorAll('[name="' + relatedNodeName + '"]')[0].parentNode;

                            if (parent !== null && $(parent).hasClass('is-checked')) {
                                errorFields.push(field);
                            }
                        }
                        else if (field.name.indexOf('TFSAExistingPlanFor') >= 0) {
                            var relatedNodeName = field.name.replace('TFSAExistingPlanFor', 'TFSAPreExisting');
                            var parent = document.querySelectorAll('[name="' + relatedNodeName + '"]')[0].parentNode;

                            if (parent !== null && $(parent).hasClass('is-checked')) {
                                errorFields.push(field);
                            }
                        }
                        else if (field.name.indexOf('RIFExistingPlanFor') >= 0) {
                            var relatedNodeName = field.name.replace('RIFExistingPlanFor', 'RIFPreExisting');
                            var parent = document.querySelectorAll('[name="' + relatedNodeName + '"]')[0].parentNode;

                            if (parent !== null && $(parent).hasClass('is-checked')) {
                                errorFields.push(field);
                            }
                        }
                        else {
                            errorFields.push(field);
                        }
                    }
                }
            },
            validateSelect: function (errorFields, container) {
                var selectFields = this.getValidatableSelects(container);

                for (var j = 0; j < selectFields.length; j++) {
                    var containerDiv = $(selectFields[j]).closest(".auto-hide");
                    var isContainerExistsAndHidden = containerDiv && containerDiv.is(":hidden");
                    if (isContainerExistsAndHidden) {
                        continue;
                    }

                    var selectValue = $(selectFields[j]).val();

                    if (!selectValue) {
                        var textInput = $(selectFields[j]).siblings("input");
                        errorFields.push({
                            key: textInput.attr("id"),
                            name: selectFields[j].getAttribute('name'),
                            type: 'select',
                            errType: 1,
                            maxVal: Number.MAX_VALUE,
                            minVal: 0
                        });
                    }
                }
            },
            validate: function (containers) {
                var errorFields = [];

                for (var i = 0; i < containers.length; i++) {
                    this.validateInputAndRadio(errorFields, containers[i]);
                    this.validateSelect(errorFields, containers[i]);
                }

                this.showValidationMessages(errorFields);

                return errorFields.length === 0;
            }
        };
        var validationResult = privateFuncs.validate(containers);

        if (!validationResult) {
            CustomValidator.OnInvalid();
        }

        return validationResult;
    }
}

function isHidden(elem) {
    return elem.offsetWidth <= 0 && elem.offsetHeight <= 0;
}