var MaterialUtils = {
    updateTextFields: function(containerSelector) {
        var $container = $(containerSelector || "body");
        $container.find('.mdl-textfield').each(function(index, element) {
            $(element).toggleClass('is-dirty', !!$("input", element).val())
        });
    },
    updateCheckboxes: function(containerSelector) {
        setTimeout(function(){
            var $container = $(containerSelector || "body");
            $container.find('.mdl-checkbox.is-checked input[type="checkbox"]:not(:checked), .mdl-checkbox:not(.is-checked) input[type="checkbox"]:checked').each(function(i, element){
                var checkbox = $(element).closest(".mdl-checkbox")[0].MaterialCheckbox;
                if(checkbox){
                    if($(element).is(":checked")) {
                        checkbox.check();
                    } else {
                        checkbox.uncheck();
                    }
                }
            });
        });
    },
    updateRadios: function(containerSelector) {
        setTimeout(function(){
            var $container = $(containerSelector || "body");
            $container.find('.mdl-radio.is-checked input[type="radio"]:not(:checked), .mdl-radio:not(.is-checked) input[type="radio"]:checked').each(function(i, element){
                var radio = $(element).closest(".mdl-radio")[0].MaterialRadio;
                if(radio){
                    if($(element).is(":checked")) {
                        radio.check();
                    } else {
                        radio.uncheck();
                    }
                }
            });
        });
    },
    resetRadios: function(selector) {
        $(selector).toArray().forEach(function(e) {
            var radio = $(e).parent()[0].MaterialRadio;
            if (radio) {
                radio.uncheck();
            }
        });
    },
    disableRadio: function() {
        this.invokeMaterialAction(arguments, disable);

        function disable(item) {
            if (item.parentNode.MaterialRadio) {
                item.parentNode.MaterialRadio.disable();
            }
        }
    },
    readonlyTextfield: function () {
        this.invokeMaterialAction(arguments, readonly);

        function readonly(item)
        {
            if (item.parentNode.MaterialTextfield) {
                item.parentNode.MaterialTextfield.disable();
                item.disabled = false;
                item.readOnly = true;
            }
        }
    },
    disableTextfield: function () {
        this.invokeMaterialAction(arguments, disable);

        function disable(item) {
            if (item.parentNode.MaterialTextfield) {
                item.parentNode.MaterialTextfield.disable();
                item.disabled = true;
            }
        }
    },
    editableTextfield: function() {
        this.invokeMaterialAction(arguments, enable);

        function enable(item) {
            if (item.parentNode.MaterialTextfield) {
                item.parentNode.MaterialTextfield.enable();
                item.readOnly = false;
            }
        }
    },
    readonlySelect: function() {
        var items = [].slice.apply(arguments);

        if (items && Array.isArray(items)) {
            for (var i = 0; i < items.length; i++) {
                $(items[i]).find('li:not(.selected)').addClass('hidden');
                this.updateTextFields(items[i]);
            }
        }
    },
    invokeMaterialAction: function(args, action) {
        var items = [].slice.apply(args);

        if (items && Array.isArray(items)) {
            for (var i = 0; i < items.length; i++) {
                if (Array.isArray(items[i])) {
                    for (var j = 0; j < items[i].length; j++) {
                        action(items[i][j]);
                    }
                } else {
                    action(items[i]);
                }
            }
        }
    },
    upgradeDom: function () {
        if (componentHandler) {
            componentHandler.upgradeDom();
            componentHandler.upgradeAllRegistered();
        }
    },
    disableCheckBox: function () {
        this.invokeMaterialAction(arguments, readonly);

        function readonly(item) {
            if (item.parentNode.MaterialCheckbox) {
                item.parentNode.MaterialCheckbox.disable();
            }
        }
    },
    enableCheckBox: function () {
        this.invokeMaterialAction(arguments, readonly);

        function readonly(item) {
            if (item.parentNode.MaterialCheckbox) {
                item.parentNode.MaterialCheckbox.enable();
            }
        }
    }
};

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

function scrollParentToChild(options) {
    var parentRect = options.parent.getBoundingClientRect();
    var childRect = options.child.getBoundingClientRect();
    var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentRect.height);

    if (options.scrollWhenVisible || !isViewable) {
        var scrollTop = (childRect.top + options.parent.scrollTop) - parentRect.top;
        switch (options.position) {
            case "top":
                break;
            case "center":
                scrollTop -= (parentRect.height - childRect.height) / 2;
                break;
            case "bottom":
                scrollTop -= parentRect.height - childRect.height;
                break;
        }
        if(options.behaviour === "smooth"){
            $(options.parent).animate({
                scrollTop: scrollTop
            }, 1000);
        } else {
            options.parent.scrollTop = scrollTop;
        }
    }
}

$(document).ready(function () {
    if (GetIEVersion() > 0) {
        /**
         * polyfill for html5 form attr
         */

        // detect if browser supports this
        var sampleElement = $('[form]').get(0);
        var isIE11 = !(window.ActiveXObject) && "ActiveXObject" in window;
        if (sampleElement &&
            window.HTMLFormElement &&
            (sampleElement.form instanceof HTMLFormElement || sampleElement instanceof window.HTMLFormElement) &&
            !isIE11) {
            // browser supports it, no need to fix
            return;
        }
        // Append a field to a form
        $.fn.appendField = function (data) {
            // for form only
            if (!this.is('form')) return;

            // wrap data
            if (!$.isArray(data) && data.name && data.value) {
                data = [data];
            }

            var $form = this;

            // attach new params
            $.each(data,
                function (i, item) {
                    $('<input/>')
                        .attr('type', 'hidden')
                        .attr('name', item.name)
                        .val(item.value).appendTo($form);
                });

            return $form;
        };

        /**
        * Find all input fields with form attribute point to jQuery object
        *
        */
        $('form[id]').submit(function (e) {
            // serialize data
            var data = $('[form=' + this.id + ']').serializeArray();
            // append data to form
            $(this).appendField(data);
        }).each(function () {
            var form = this,
                $fields = $('[form=' + this.id + ']');

            $fields.filter('button, input').filter('[type=reset],[type=submit]').click(function () {
                var type = this.type.toLowerCase();
                if (type === 'reset') {
                    // reset form
                    form.reset();
                    // for elements outside form
                    $fields.each(function () {
                        this.value = this.defaultValue;
                        this.checked = this.defaultChecked;
                    }).filter('select').each(function () {
                        $(this).find('option').each(function () {
                            this.selected = this.defaultSelected;
                        });
                    });
                } else if (type.match(/^submit|image$/i)) {
                    $(form).appendField({ name: this.name, value: this.value }).submit();
                }
            });
        });
    }
});