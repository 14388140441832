var setOnlyNumbers = function (event) {
    var isDepositInput = $(event.target).hasClass("deposit-input");
    var isDecimalNumber = $(event.target).hasClass("decimal-number");    
    var regex = (isDepositInput || isDecimalNumber) ? new RegExp(/^([0-9]+(\.[0-9]+)?)/) : new RegExp(/[0-9]/g);
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (isDepositInput) {
        key = event.target.value + key;
        var inputLength = key.length;
        var indexOfDot = key.indexOf('.');
        if (indexOfDot > 0) {
            var digitsAfterDot = inputLength - indexOfDot;
            if (digitsAfterDot > 3 || key.match(/\./g).length > 1) {
                return false;
            }
        } 
    }

    if (!regex.test(key)) {
        event.preventDefault();
        return false;
    }
};
var sPMaskBehavior = function (srcVal, e, currentField, options) {
    var val = srcVal || '';
    if (val === '') {
        return "#";
    }
    if (val.indexOf('.00') > 0) {
        return "#.00";
    }
    return "#";
};
var spOptions = {
    onKeyPress: function (val, event, currentField, options) {
        currentField.mask(sPMaskBehavior.apply({}, arguments), options);
    },
    reverse: true
};
$(".dollar-sign").find("input").not('.deposit-input').mask(sPMaskBehavior, spOptions);
$(".dollar-sign input").on("keypress", setOnlyNumbers);

$('.mask.sin').mask('000-000-000');
$('.mask.phone-number').mask('(000)000-0000');
$('.mask.dollar-amount').mask('#,##0.00', { reverse: true });

// Postal/Zip code
$('.mask.postal-code, .mask.zip').mask('ABA BAB', {
    translation: {
        A: {
            pattern: /[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]/
        },
        B: {
            pattern: /[0-9]/
        }
    }
});

$('.mask.day').mask('00');
$('.mask.month').mask('00');
$('.mask.year').mask('0000');