var sessionTime = parseInt(window.InBranchSession);
var popUPShow = parseInt(window.PopupDisplayTime);


var sessionModalShow = popUPShow * 60;
var timer = null;
var idleTime = 0;
var startTime = Date.now();
var remainingTime = sessionTime * 60;

//Online
var sessionTimeOnline = parseInt(window.SessionOnline);
var popUPShowOnline = parseInt(window.PopupDisplayTimeOnline);

var sessionModalShowOnline = popUPShowOnline * 60;
var timerOnline = null;
var idleTimeOnline = 0;
var startTimeOnline = Date.now();
var remainingTimeOnline = sessionTimeOnline * 60;

// new variable to track whether the modal is currently shown
var modalShown = false;
var modalExpiredShown = false;

$("#resetTimeButton").click(function (e) {
    var url = new URL(e.target.baseURI);
    var urlSessionId = new URLSearchParams(url.search);
    var sessionId = urlSessionId.get("id"); 
    $.ajax({
        type: "POST",
        url: "/application/UpdateInbranchCookieExpiryTime/",
        data: {
            id: sessionId, // < note use of 'this' here           
        }
    });
});
$("#resetTimeButtonOnline").click(function (e) {
    var url = new URL(e.target.baseURI);
    var urlSessionId = new URLSearchParams(url.search);
    var sessionId = urlSessionId.get("id");
    $.ajax({
        type: "POST",
        url: "/application/UpdateInbranchCookieExpiryTime/",
        data: {
            id: sessionId, // < note use of 'this' here           
        }
    });
});

$("#resetTimeButtonOnlineClose").click(function (e) {
    var url = new URL(e.target.baseURI);
    var urlSessionId = new URLSearchParams(url.search);
    var sessionId = urlSessionId.get("id");
    window.location.replace(url.href);    
});
// Check idle time and show modal if necessary
function checkIdleTime() {    
    idleTime = remainingTime;
    if (idleTime <= sessionModalShow && !$('#sessionModal').hasClass('show') && !$('#sessionExpiredModal').hasClass('show')) {
        $('#sessionModal').modal('show');
        $('div').removeClass('modal-backdrop');
        $('.modal-open').css("pointer-events", " none");
        // Reset idle time
        idleTime = 0;
        // set modalShown to true
        modalShown = true;
        if (!$('#sessionModal').hasClass('remaining-time-set')) {
            $('#sessionModal').addClass('remaining-time-set');
            $(document).on("keydown", preventFunctionKeys);
        }
    }
}

function checkIdleTimeForOnline() {
    idleTimeOnline = remainingTimeOnline;    
    if (idleTimeOnline <= sessionModalShowOnline && !$('#sessionModalOnline').hasClass('show') && !$('#sessionExpiredModalOnline').hasClass('show')) {
        $('#sessionModalOnline').modal('show');
        $('div').removeClass('modal-backdrop');
        $('.modal-open').css("pointer-events", " none");
        // Reset idle time
        idleTimeOnline = 0;
        // set modalShown to true
        modalShown = true;
        if (!$('#sessionModalOnline').hasClass('remaining-time-set')) {
            $('#sessionModalOnline').addClass('remaining-time-set');
            $(document).on("keydown", preventFunctionKeys);
        }
    }
}



// Reset idleTime and remainingTime if user interacts
$(document).on('click keypress mousemove', function () {
    idleTime = 0;
    idleTimeOnline = 0;
    if ($('#sessionModal').hasClass('show')) {
        $(document).on("keydown", preventFunctionKeys);
        window.addEventListener('contextmenu', preventContextMenu);
        // Clear remaining time flag
        $('#sessionModal').removeClass('remaining-time-set');
    }
    else {
        if (!modalShown && !modalExpiredShown) {
            // Reset remaining time if not shown
            remainingTime = sessionTime * 60;
            startTime = Date.now();
            /*to remove the event listener*/
            $(document).off("keydown", preventFunctionKeys);
            window.removeEventListener('contextmenu', preventContextMenu);
        }
        if (modalExpiredShown) {
            $(document).on("keydown", preventFunctionKeys);
        }
    }

    if ($('#sessionModalOnline').hasClass('show')) {
        $(document).on("keydown", preventFunctionKeys);
        window.addEventListener('contextmenu', preventContextMenu);
        // Clear remaining time flag
        $('#sessionModalOnline').removeClass('remaining-time-set');
    }
    else {
        if (!modalShown && !modalExpiredShown) {
            // Reset remaining time if not shown
            remainingTimeOnline = sessionTimeOnline * 60;
            startTimeOnline = Date.now();
            /*to remove the event listener*/
            $(document).off("keydown", preventFunctionKeys);
            window.removeEventListener('contextmenu', preventContextMenu);
        }
        if (modalExpiredShown) {
            $(document).on("keydown", preventFunctionKeys);
        }
    }
});



// Reset remainingTime on scroll
$(window).on('scroll', function () {
    remainingTime = sessionTime * 60;
    remainingTimeOnline = sessionTimeOnline * 60;
    startTime = Date.now();
});



// Add click event listener to reset time button
$('#resetTimeButton').on('click', function () {
    // Reset remaining time
    remainingTime = sessionTime * 60;
    startTime = Date.now();
    // Update modal display
    $('#modalRemainingTime').text('60:00');
    $('.modal-open').css("pointer-events", "auto");
});

$('#resetTimeButtonOnline').on('click', function () {
    // Reset remaining time
    remainingTimeOnline = sessionTimeOnline * 60;
    startTimeOnline = Date.now();
    // Update modal display
    $('#modalRemainingTimeOnline').text('60:00');
    $('.modal-open').css("pointer-events", "auto");
});



// Update the timer recursively using requestAnimationFrame
function updateTimer() {
    var currentTime = Date.now();
    var elapsedTime = Math.floor((currentTime - startTime) / 1000); // Calculate elapsed time in seconds
    idleTime += elapsedTime;
    remainingTime = sessionTime * 60 - elapsedTime;
    if (remainingTime <= 0) {
        remainingTime = 0;
        clearInterval(timer);
        // Implement the css on different scenarios
        $('#sessionModal').modal('hide');
        $('#sessionExpiredModal').modal('show');
        modalExpiredShown = true;
        $('#sideBar').css("pointer-events", "none");
        $('#sideBar').css("cursor", "not-allowed");
        $('.modal-open').css("pointer-events", "none");
        $('.modal-open').css("cursor", "not-allowed");
        $('div').removeClass('modal-backdrop');
    }
    else {
        var minutes = Math.floor(remainingTime / 60);
        var seconds = remainingTime % 60;
        $('#remainingTimeDisplay').text(
            minutes + " minutes" + (seconds < 10 ? " 0" : " ") + seconds + " seconds"
        );

        if ($('#sessionModal').hasClass('show')) {
            $('#modalRemainingTime').text(
                minutes + " minutes" + (seconds < 10 ? " 0" : " ") + seconds + " seconds"
            );
        }
        else {
            $('#modalRemainingTime').text('');
            checkIdleTime();
        }
    }
    requestAnimationFrame(updateTimer);
}

function updateTimerOnline() {
    var currentTime = Date.now();
    var elapsedTime = Math.floor((currentTime - startTimeOnline) / 1000); // Calculate elapsed time in seconds
    idleTimeOnline += elapsedTime;
    remainingTimeOnline = sessionTimeOnline * 60 - elapsedTime;
    if (remainingTimeOnline <= 0) {
        remainingTimeOnline = 0;
        clearInterval(timerOnline);
        // Implement the css on different scenarios
        $('#sessionModalOnline').modal('hide');
        $('#sessionExpiredModalOnline').modal('show');
        modalExpiredShown = true;
        $('#sideBar').css("pointer-events", "none");
        $('#sideBar').css("cursor", "not-allowed");
        $('.modal-open').css("pointer-events", "none");
        $('.modal-open').css("cursor", "not-allowed");
        $('div').removeClass('modal-backdrop');
    }
    else {
        var minutes = Math.floor(remainingTimeOnline / 60);
        var seconds = remainingTimeOnline % 60;
        $('#remainingTimeDisplayOnline').text(
            minutes + " minutes" + (seconds < 10 ? " 0" : " ") + seconds + " seconds"
        );

        if ($('#sessionModalOnline').hasClass('show')) {
            $('#modalRemainingTimeOnline').text(
                minutes + " minutes" + (seconds < 10 ? " 0" : " ") + seconds + " seconds"
            );
        }
        else {
            $('#modalRemainingTimeOnline').text('');
            checkIdleTimeForOnline();
        }
    }
    requestAnimationFrame(updateTimerOnline);
}

// Start the timer initially
timer = setInterval(updateTimer, 1000);
timerOnline = setInterval(updateTimerOnline, 1000);

// Reset remainingTime and modalShown variable when modal is hidden
$('#sessionModal').on('hidden.bs.modal', function () {
    remainingTime = sessionTime * 60;
    startTime = Date.now();
    modalShown = false;
});

$('#sessionModalOnline').on('hidden.bs.modal', function () {
    remainingTimeOnline = sessionTimeOnline * 60;
    startTime = Date.now();
    modalShown = false;
});

function preventFunctionKeys(event) {
    if ((event.which >= 112 && event.which <= 123) || event.which == 9 || event.which == 20) {
        event.preventDefault();
    }
}
function preventContextMenu(event) {
    event.preventDefault();
}
