var uploadReplaced = false;

$(document).ready(function () {
    $('.pad-select .pad-select-item').on('click', function () {
        var $selectItem = $(this).closest('.pad-select-item');
        var targetClass = $selectItem.attr('data-target');

        $('.pad-content .pad-content-item').hide();
        $('.pad-content .' + targetClass).show();
    });

    $('.select-chequing-account').on('change',
        function() {
            var $this = $(this);

            $('#chequing-account-number').val($this.val());
            MaterialUtils.updateTextFields();
        });

    $(".img-sample").addClass("hidden");

    $(".sampleTrigger").click(function (e) {
        e.preventDefault();
        var sampleId = $(this).data("toggle"),
            $sampleImg = $("#" + sampleId);
        $sampleImg.toggleClass("hidden");
    });

    $('#existingAccountToolTip').on('click',
        function () {
            $('#existingAccountToolTip-info').toggle();
        });

    function uploadReplacement() {
        $(".upload").each(function (i) {
            var sender$ = $(this);
            var button$ = sender$.find("button");
            var upload$ = sender$.find("input[type=file]");

            button$.width(button$.width() + 1);
            sender$.addClass("upload-control").removeClass("form-group");
            upload$.css({ width: button$.outerWidth() + "px", height: button$.outerHeight() + "px" });

            var buttonLayer = $("<span>",
                {
                    "class": "button-overlay",
                    css: {
                        width: button$.outerWidth() + "px",
                        height: button$.outerHeight() + "px"
                    }
                });

            sender$.append(buttonLayer);

            if (i === 0 && sender$.parent().data("pull-left") === true) {
                sender$.parent().addClass("pull-left");
            }

            (function (button, upload) {
                upload.change(function () {
                    button.click();
                    upload.css("visibility", "hidden");
                });
            })(button$, upload$);

        });

        uploadReplaced = true;
    }

    if ($('#has-show-preview').val() == 'true' || $('#AccountFor_AnotherFI').is(':checked')) {
        uploadReplacement();
    }

    $('[data-target="existing-account-another-fi"]').on('click', function () {
        if (!uploadReplaced) {
            uploadReplacement();
        }
    });
});