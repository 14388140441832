function InitFirstTimeBuyerControl() {
    $(".first-time-buyer-container label").on("click", function () {
        _processFirstTimeBuyerValue();
    });
    _processFirstTimeBuyerValue();

    function _processFirstTimeBuyerValue() {
        var isFirst = !$(".first-time-buyer-container input[value='False']").is(":checked");
        var $sellingProperty = $(".has-selling-property");
        if (isFirst) {
            $sellingProperty.hide();
            $("input[name='HasSellingProperty']").parent("label").removeClass("is-checked");
            $("input[name='HasSellingProperty']").prop('checked', false);

            var sweatequiltydownpaymentsourceenabled = $(".sweatequiltydownpaymentsourceenabled");
            if (sweatequiltydownpaymentsourceenabled.is(":checked")) {
                sweatequiltydownpaymentsourceenabled.click();
            }
            sweatequiltydownpaymentsourceenabled.parent("label").hide();

            var saleofanotherpropertydownpaymentsourceenabled = $(".saleofanotherpropertydownpaymentsourceenabled");
            if (saleofanotherpropertydownpaymentsourceenabled.is(":checked")) {
                saleofanotherpropertydownpaymentsourceenabled.click();
            }
            saleofanotherpropertydownpaymentsourceenabled.parent("label").hide();

            var existinghomeequiltydownpaymentsourceenabled = $(".saleofanotherpropertydownpaymentsourceenabled");
            if (existinghomeequiltydownpaymentsourceenabled.is(":checked")) {
                existinghomeequiltydownpaymentsourceenabled.click();
            }
            existinghomeequiltydownpaymentsourceenabled.parent("label").hide();

        } else {
            $sellingProperty.show();

            $(".sweatequiltydownpaymentsourceenabled").parent("label").show();
            $(".saleofanotherpropertydownpaymentsourceenabled").parent("label").show();
            $(".existinghomeequiltydownpaymentsourceenabled").parent("label").show();
        }

        _processDownPaymentSourceValue();
    }
}

function InitDownPaymentSourceControl() {
    $(".down-payment-source").on("change", function () {
        _processDownPaymentSourceValue();
    });
    _processDownPaymentSourceValue();
}

function _processDownPaymentSourceValue() {
    $(".down-payment-source").each(function (key, value) {

        var downPaymentSourceValue = $(value).data("downpaymentsourcetype");
        var isChecked = $(value).is(':checked');
        var container;

        switch (downPaymentSourceValue) {
            case 1:
                container = $(".personalsavingsdownpaymentamount-container");
                break;
            case 2:
                container = $(".tfsadownpaymentamount-container");
                break;
            case 3:
                container = $(".rspdownpaymentamount-container");
                break;
            case 4:
                container = $(".giftedfromfamilydownpaymentamount-container");
                break;
            case 5:
                container = $(".sweatequiltydownpaymentamount-container");
                break;
            case 6:
                container = $(".saleofanotherpropertydownpaymentamount-container");
                break;
            case 7:
                container = $(".existinghomeequiltydownpaymentamount-container");
                break;
            case 8:
                container = $(".down-payment-source-other-container");
                break;
            case 9:
                container = $(".grantdownpaymentamount-container");
                break;
            case 10:
                container = $(".fhsadownpaymentamount-container");
                break;
        }


        container.toggleClass("hidden", !isChecked);
        if (!isChecked) {
            container.find(".mdl-textfield__input").val(null);
            container.find(".mdl-textfield").removeClass('is-dirty');
        }

        //PersonalSavings = 1
        //Tfsa = 2
        //Rrsp = 3
        //NonRepayableGift = 4
        //SweatEquity = 5
        //EquityFromSale = 6
        //ExistingHomeEquity = 7
        //Other = 8
        //Grant = 9
    });
}

InitFirstTimeBuyerControl();
InitDownPaymentSourceControl();
