$(function () {
    $(".radio-toggle").on("change", function () {
        var self = $(this),
            isYes = self.val() === "True",
            isChecked = self.prop("checked"),
            toggleGroup = self.data("toggle"),
            $container = self.closest("#mortgage-form");

        switch (toggleGroup) {
            case ".mortgage-on-property-details":
                $container.find(toggleGroup).find(".payment-include-taxes[value=True]").click();

                if (!isYes && isChecked) {
                    $container.find(".payment-include-taxes-details").show("slow");
                } else {
                    $container.find(".payment-include-taxes-details").hide("slow");
                }

                break;
            case ".payment-include-taxes-details":
                if ($container.find(".mortgage-on-property[value=True]").prop("checked")) {
                    isYes = !isYes;
                }

                break;
        }

        if (isYes && isChecked) {
            $container.find(toggleGroup).show("slow");
        } else {
            $container.find(toggleGroup).hide("slow");
        }
    });
});