$(document).ready(function () {
    var testAgreementKey = "testAgreementBusiness" + localStorageImportantKey;

    if (!localStorage.getItem(testAgreementKey)) {
        $("#testAgrModalContainer").removeClass("hidden");
        $("body").addClass("overflow-hidden");

        $("#testAgreement").click(function () {
            if ($(this).is(":checked")) {
                $("#continueBtn").removeAttr("disabled");
            } else {
                $("#continueBtn").attr("disabled", "disabled");
            }
        });

        $("#continueBtn").click(function () {
            $("body").removeClass("overflow-hidden");
            $("#testAgrModalContainer").addClass("hidden");
            localStorage.setItem(testAgreementKey, true);
        });
    }
});