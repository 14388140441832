$('#password-toggle-icon').on('click', function () {
    var passwordField = document.getElementById("toggle-password");

    if (passwordField.type === "password") {
        passwordField.type = "text";
        $(this).text("visibility_off");
    } else {
        passwordField.type = "password";
        $(this).text("visibility");
    }
});

$('#confirm-password-toggle-icon').on('click', function () {
    var passwordField = document.getElementById("confirm-toggle-password");

    if (passwordField.type === "password") {
        passwordField.type = "text";
        $(this).text("visibility_off");
    } else {
        passwordField.type = "password";
        $(this).text("visibility");
    }
});

$("#toggle-password").on("input", function () {
    var isAllValid = true;

    //length check
    if (this.value.length > 8) {
        $("#setup-psw-length").addClass('val-active');
        $("#setup-psw-length-ico").text("check_box");
    }
    else {
        $("#setup-psw-length").removeClass('val-active');
        $("#setup-psw-length-ico").text("");

        isAllValid = false;
    }

    //upper and lowwer letters check
    if (this.value.toUpperCase() !== this.value && this.value.toString().toLowerCase() !== this.value.toString()) {
        $("#setup-psw-style").addClass('val-active');
        $("#setup-psw-style-ico").text("check_box");
    }
    else {
        $("#setup-psw-style").removeClass('val-active');
        $("#setup-psw-style-ico").text("");

        isAllValid = false;
    }

    //number ckeck
    if (this.value.match(/\d+/g) !== null) {
        $("#setup-psw-number").addClass('val-active');
        $("#setup-psw-number-ico").text("check_box");
    }
    else {
        $("#setup-psw-number").removeClass('val-active');
        $("#setup-psw-number-ico").text("");

        isAllValid = false;
    }

    //symbol ckeck
    if (this.value.indexOf("@") >= 0 || this.value.indexOf("#") >= 0 || this.value.indexOf("%") >= 0 || this.value.indexOf("!") >= 0) {
        $("#setup-psw-symbol").addClass('val-active');
        $("#setup-psw-symbol-ico").text("check_box");
    }
    else {
        $("#setup-psw-symbol").removeClass('val-active');
        $("#setup-psw-symbol-ico").text("");

        isAllValid = false;
    }
    //space ckeck
    var checkSpace = this.value.match(/[\s]/g);
    if (checkSpace != null) {       
        $("#setup-psw-space").removeAttr('hidden', false);
        isAllValid = false;
    }
    else {
        $("#setup-psw-space").attr('hidden', true);
    }

    if (isAllValid) {
        $("#btn-setup-submit").prop("disabled", false);
        $("#btn-reset-password").prop("disabled", false);
    }
    else {
        $("#btn-setup-submit").prop("disabled", true);
        $("#btn-reset-password").prop("disabled", true);
    }
});

$("#MemberProfile_Password").on("input", function () {
    var isContainInvalidCharacters = false;

    var pswMin = $(this).data('val-psw-min');
    var pswMax = $(this).data('val-psw-max');
    if (!pswMin || !pswMax)
        return;

    //lowwer letter(s) check
    var pswLowMin = $(this).data('val-psw-low-min');
    var val = this.value.match(/[a-z]/g);
    if (pswLowMin == 1000 && val != null) {
        $("#mbPassword-lower-notallowed-validation").removeAttr('hidden', false);
    }
    if (pswLowMin == 1000 && val == null) {
        $("#mbPassword-lower-notallowed-validation").attr('hidden', true);
    }
    if ($.isNumeric(pswLowMin) && val && val.length >= pswLowMin) {
        $("#mbPassword-psw-low").addClass('val-active');
        $("#mbPassword-psw-low-ico").text("check_box");
    } else {
        $("#mbPassword-psw-low").removeClass('val-active');
        $("#mbPassword-psw-low-ico").text("");

        if (!isContainInvalidCharacters && val)
            isContainInvalidCharacters = val.length > 0;
    }

    //upper letter(s) check
    var pswUppMin = $(this).data('val-psw-upp-min');
    val = this.value.match(/[A-Z]/g);
    if (pswUppMin == 1000 && val != null) {
        $("#mbPassword-psw-upp-notallowed-validation").removeAttr('hidden', false);
    }
    if (pswUppMin == 1000 && val == null) {
        $("#mbPassword-psw-upp-notallowed-validation").attr('hidden', true);
    }
    if ($.isNumeric(pswUppMin) && val && val.length >= pswUppMin) {
        $("#mbPassword-psw-upp").addClass('val-active');
        $("#mbPassword-psw-upp-ico").text("check_box");
    } else {
        $("#mbPassword-psw-upp").removeClass('val-active');
        $("#mbPassword-psw-upp-ico").text("");

        if (!isContainInvalidCharacters && val)
            isContainInvalidCharacters = val.length > 0;
    }

    //number ckeck
    var pswNumMin = $(this).data('val-psw-num-min');
    val = this.value.match(/\d/g);
    if (pswNumMin == 1000 && val != null) {
        $("#mbPassword-psw-number-notallowed-validation").removeAttr('hidden', false);
    }
    if (pswNumMin == 1000 && val == null) {
        $("#mbPassword-psw-number-notallowed-validation").attr('hidden', true);
    }
    if ($.isNumeric(pswNumMin) && val && val.length >= pswNumMin) {
        $("#mbPassword-psw-number").addClass('val-active');
        $("#mbPassword-psw-number-ico").text("check_box");
    } else {
        $("#mbPassword-psw-number").removeClass('val-active');
        $("#mbPassword-psw-number-ico").text("");

        if (!isContainInvalidCharacters && val)
            isContainInvalidCharacters = val.length > 0;
    }

    //symbol ckeck
    var pswSymMin = $(this).data('val-psw-sym-min');
    var pswSyms = $(this).data('val-psw-syms');
    var checkSyms = this.value.match(new RegExp('[^A-Za-z0-9]', 'g'));
    if (pswSymMin == 1000 && checkSyms != null) {
        $("#mbPassword-psw-syms-notallowed-validation").removeAttr('hidden', false);
    }
    if (pswSymMin == 1000 && checkSyms == null) {
        $("#mbPassword-psw-syms-notallowed-validation").attr('hidden', true);
    }
    var sysm = "";
    for (var i = 0; i < pswSyms.length; i++) {
        sysm += '\\' + pswSyms.charAt(i);
    }

    val = this.value.match(new RegExp('[' + sysm + ']', 'g'));
    if (pswSyms && $.isNumeric(pswSymMin) && val && val.length >= pswSymMin) {
        $("#mbPassword-psw-symbol").addClass('val-active');
        $("#mbPassword-psw-symbol-ico").text("check_box");
    } else {
        $("#mbPassword-psw-symbol").removeClass('val-active');
        $("#mbPassword-psw-symbol-ico").text("");
    }
    var nonVal = this.value.match(new RegExp('[^A-Za-z0-9' + sysm + ']', 'g'));
    if (!isContainInvalidCharacters && nonVal)
        isContainInvalidCharacters = nonVal.length > 0;

    //length check && characters
    if (this.value.length >= pswMin && this.value.length <= pswMax && !isContainInvalidCharacters) {
        $("#mbPassword-psw-length").addClass('val-active');
        $("#mbPassword-psw-length-ico").text("check_box");
    } else {
        $("#mbPassword-psw-length").removeClass('val-active');
        $("#mbPassword-psw-length-ico").text("");
    }
    //space ckeck
    var checkSpace = this.value.match(/[\s]/g);
    if (checkSpace != null) {        
        $("#mbPassword-psw-space-notallowed-validation").removeAttr('hidden', false);
    }    
    else {
        $("#mbPassword-psw-space-notallowed-validation").attr('hidden', true);  
        }
});

$(document).ready(function () {
    $(".autocomplete").each(function (index, element) {
        initAutocomplete($(element));
    });
});

function initAutocomplete($container) {
    var $autocomplete = $(".autocomplete-text", $container);
    var $autocompleteValue = $(".autocomplete-value", $container);
    var $options = $("li:not(.no-match)", $container);
    var $noMatch = $("li.no-match", $container);
    var $valueNotSelected = $("li.value-not-selected", $container);
    $autocomplete.removeAttr("name");
    var selectedOption = $("li[value='" + $autocompleteValue.val() + "']:not(.no-match,.value-not-selected)", $container);
    set(selectedOption);
    var selected;
    close();

    $autocomplete.on("keydown", function (e) {
        if (e.which === 13) { // Enter
            e.preventDefault();
            if (selected !== -1) {
                var option = getVisibleOptions()[selected];
                set($(option));
                validate();
            }
            return false;
        }
    });

    $autocomplete.on("keyup", function (e) {
        e.preventDefault();
        switch (e.which) {
            case 13:  // Enter
                return false;
            case 38:  // Up
                if (selected > 0) {
                    selected--;
                    selectOption();
                }
                return false;
            case 40:  // Down
                if (selected < getVisibleOptions().length - 1) {
                    selected++;
                    selectOption();
                }
                return false;
        }
        selected = -1;
        $options.removeClass("selected");
        var value = $(this).val();
        $autocompleteValue.val("");

        var optionsCount = $options.length;
        $options.each(function (index, option) {
            if ($(option).attr("value").toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                $(option).show();
            } else {
                $(option).hide();
                optionsCount--;
            }
        });
        if (optionsCount === 0) {
            $noMatch.show();
        } else {
            $noMatch.hide();
        }
    });

    $options.on("click", function () {
        clearTimeout(timer);
        set($(this));
        validate();
    });

    $noMatch.on("click", function () {
        clearTimeout(timer);
        set(null);
        $options.show();
    });

    $valueNotSelected.on("click", function () {
        clearTimeout(timer);
        set(null);
        $options.show();
    });

    var timer;
    $autocomplete.on("blur", function (e) {
        e.preventDefault();

        timer = setTimeout(function () {
            close();
        }, 300);
    });

    $container.on("set", function (e, key) {
        var selected = $options.filter(function () {
            return $(this).text().toLowerCase() === key.toLowerCase();
        });
        if (selected && selected.length === 1) {
            set($(selected));
            validate();
        }
    });

    $container.on("clear", function (e) {
        set(null);
    });

    $container.on("enabled", function (e, enabled) {
        $autocomplete.attr("disabled", !enabled);
    });

    function getVisibleOptions() {
        return $options.filter(function (index, option) {
            return $(option).is(":visible");
        });
    }

    function set($option) {
        $autocomplete.val($option ? $option.text() : "");
        $autocompleteValue.val($option ? $option.attr("value") : "");
        close();
        MaterialUtils.updateTextFields($container);
    }

    function close() {
        if ($noMatch.is(":visible")) {
            $valueNotSelected.show();

            $valueNotSelected.parent().next('.text-danger').removeClass('d-block');
            $valueNotSelected.parent().next('.text-danger').addClass('hidden');
        }
        else {
            $valueNotSelected.hide();
            $options.hide();

            $valueNotSelected.parent().next('.text-danger').removeClass('hidden');
            $valueNotSelected.parent().next('.text-danger').addClass('d-block');
        }

        $noMatch.hide();
        selected = -1;
    }

    function selectOption() {
        $options.removeClass("selected");
        var selectedOption = getVisibleOptions()[selected];
        $(selectedOption).addClass("selected");
        scrollParentToChild({
            parent: $(selectedOption).parent()[0],
            child: selectedOption
        });
    }

    function scrollTo(container, target) {
        var $target = $(target);
        container.scrollTop($target[0].offsetTop);
    }

    function validate() {
        $autocompleteValue.valid();
    }
}

function GetIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");
    if (Idx > 0) {
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
    }

    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) {
        return 11;
    }
    else {
        return 0; //It is not IE
    }
}

$(document).ready(function () {
    if (GetIEVersion() > 0) {
        $("input[type=radio]").on("change", function () { $(this).valid(); });
        $("html").addClass("ie");

        $(".next").on("click", function (e) {
            var nextBtn = $(this);
            var form = $(document.forms[0]);
            form.on("submit", preventFormSubmitHandler);

            setTimeout(function () {
                form.validate();
                if (form.valid() && !nextBtn.attr('data-redirect-to')) {
                    form.off("submit", preventFormSubmitHandler);
                    document.forms[0].submit();
                }
                else {
                    form.off("submit", preventFormSubmitHandler);
                    var $valContainer = $('.ie-validate');
                    if ($valContainer.length > 0) {
                        var $input = $('.ie-validate .input-validation-error:first');
                        while ($input.is(":visible") !== true) {
                            $input.removeClass('input-validation-error');
                            $input = $('body .input-validation-error:first');
                        }

                        if ($input !== null && $input.length > 0) {
                            if ($input.hasClass('invisible-for-autocomplete')) {
                                $input.css("top", "-300px");
                                $input.focus();
                            }
                            else {
                                $input.parent()
                                    .append(
                                        $('<input class="invisible-for-autocomplete" style="top:-300px" />')
                                    );
                                $input.parent().find('.invisible-for-autocomplete').focus();
                            }

                        }
                    }
                }
            });
        });
    }
});

$(".noteToggle").addClass("hidden").find(".info-close").click(function () {
    $(this).closest(".noteToggle").addClass("hidden");
});

$(".noteTrigger").click(function (e) {
    e.preventDefault();
    var noteId = $(this).data("toggle"),
        $note = $("#" + noteId);
    $note.toggleClass("hidden");
});

$("form").on("submit", function () {
    if ($(this).valid()) {
        $(this).find(".disable-on-submit").attr('disabled', 'disabled');
    }
});

$(document).ready(function () {
    $(window).keydown(function (event) {
        if (event.keyCode === 13) {
            var $nextButton = $(".next");
            if ($nextButton.length > 0 && !$nextButton.is(":visible:not(:disabled)")) {
                event.preventDefault();
                return false;
            }
        }
    });
});

function preventFormSubmitHandler() {
    return false;
}