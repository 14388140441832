jQuery.validator.addMethod("mustbetrue", function (value, element, param) {
    return element.checked;
});

jQuery.validator.unobtrusive.adapters.addBool("mustbetrue");

jQuery.validator.addMethod("mustbefalse", function (value, element, param) {
    return !element.checked;
});

jQuery.validator.unobtrusive.adapters.addBool("mustbefalse");

jQuery.validator.addMethod("requiredif", function (value, element, params) {

    var namePrefix = "", lastPart = element.name.lastIndexOf('.');
    if (lastPart !== -1) {
        namePrefix = element.name.substring(0, lastPart + 1);
    }

    var actualValue = $('[name="' + namePrefix + params.propertyname + '"]').val();
    var desiredValues = params.desiredvalue.toLowerCase().split(',');
    var controlType = params.controltype.toLowerCase();
    var matched = false;

    if (controlType == "radio") {
        actualValue = $('[name="' + namePrefix + params.propertyname + '"]:checked').val();        
    }

    if (actualValue === undefined || actualValue == null) {
        actualValue = "";
    }

    for (var i = 0; i < desiredValues.length; i++) {
        if (actualValue.toLowerCase() == desiredValues[i].toLowerCase()) {
            matched = true;
            break;
        }
    }

    //actualValue.toLowerCase() == params.desiredvalue.toLowerCase()
    if (matched && !value) {
        return false;
    }
    return true;
});

jQuery.validator.unobtrusive.adapters.add('requiredif', ['propertyname', 'desiredvalue', 'controltype'],
    function (options) {
        options.rules['requiredif'] = options.params;
        options.messages['requiredif'] = options.message;
    });

jQuery.validator.addMethod("requiredifnot", function (value, element, params) {
    var namePrefix = "", lastPart = element.name.lastIndexOf('.');
    if (lastPart !== -1) {
        namePrefix = element.name.substring(0, lastPart + 1);
    }

    var actualValue = $('[name="' + namePrefix + params.propertyname + '"]').val();
    if (actualValue === undefined || actualValue == null) {
        actualValue = "";
    }

    if (actualValue.toLowerCase() != params.desiredvalue.toLowerCase() && !value) {
        return false;
    }
    return true;
});

jQuery.validator.unobtrusive.adapters.add('requiredifnot', ['propertyname', 'desiredvalue'],
    function (options) {
        options.rules['requiredifnot'] = options.params;
        options.messages['requiredifnot'] = options.message;
    });

// file upload validator
jQuery.validator.addMethod("uploadvalidator", function (value, element, params) {
    if (value.length === 0) {
        return true;
    }
    return (new RegExp('(' + params.extensions + ')$')).test(value);
});

jQuery.validator.unobtrusive.adapters.add('uploadvalidator', ['extensions'],
    function (options) {
        options.rules['uploadvalidator'] = options.params;
        options.messages['uploadvalidator'] = options.message;
    });
;

// checkboxlist validator
// NOTE: $("form").data("validator").settings.ignore = ""; on page to allow validation of a hidden field
jQuery.validator.addMethod("checkboxlistvalidator", function (value, element, params) {
    
    var cbClass = $(element).data("val-class"),
        checked = $("." + cbClass + ":checked").length;

    return checked >= params.minimum;
});

jQuery.validator.unobtrusive.adapters.add('checkboxlistvalidator', ['minimum'], function (options) {
    options.rules['checkboxlistvalidator'] = options.params;
    options.messages['checkboxlistvalidator'] = options.message;
});

jQuery.validator.addMethod(
    'isnotequal',
    function (value, element, params) {
        if (!this.optional(element)) { 
            return params.comparedstring !== value;
        }
        return true;
    });

jQuery.validator.unobtrusive.adapters.add(
    'isnotequal', ['comparedstring'], function (options) {
        var params = {
            comparedstring: options.params.comparedstring
        };
        options.rules['isnotequal'] = params;
        options.messages['isnotequal'] = options.message;
    });

jQuery.validator.addMethod("sinvalidatorif", function (value, element, params) {
    if (isZerosSIN(value))
    {
        return params.allowzeros == 'True';
    }

    var namePrefix = "";
    var lastPart = element.name.lastIndexOf('.');

    if (lastPart !== -1) {
        namePrefix = element.name.substring(0, lastPart + 1);
    }

    var actualValue = $('[name="' + namePrefix + params.propertyname + '"]').val();
    var IsSinOptional = $('[name="' + namePrefix + "IsSinOptional" + '"]').val();
    if (IsSinOptional != undefined && IsSinOptional != null && IsSinOptional === 'True' && value === '') {
        return true;
    }

    if (actualValue === undefined || actualValue == null) {
        actualValue = "";
    }

    return actualValue.toLowerCase() != params.desiredvalue.toLowerCase() || isSINValid(value);
});

jQuery.validator.unobtrusive.adapters.add('sinvalidatorif', ['propertyname', 'desiredvalue', 'allowzeros'],
    function (options) {
        options.rules['sinvalidatorif'] = options.params;
        options.messages['sinvalidatorif'] = options.message;
    });


var isZerosSIN = function (sin)
{
    return /^0{3}-0{3}-0{3}$/.test(sin)
}

var isSINValid = function (sin) {
    var totalNumbersSum = sin.replace(/-/g, "")
        .split("")
        .reverse()
        .reduce(function (acc, curr, index) {
            var value = +curr;

            if ((index + 1) % 2 == 0) {
                value *= 2;

                if (value > 9) {
                    value -= 9;
                }
            }

            return acc + value;
        }, 0);

    return totalNumbersSum != 0 && totalNumbersSum % 10 == 0;
}