//ASAPP.FieldTracking = {
//    hasChanges: false
//};

//ASAPP.GA.trackEvent = function () {
//    var $trigger = $(this),
//        category = $trigger.data("evtcategory"),
//        action = $trigger.data("evtaction");

//    if (category) {
//        ASAPP.GA.send(category, action);
//    }
//};

//ASAPP.GA.trackFocus = function () {
//    var $trigger = $(this);

//    if (!$trigger.hasClass("focused")) {
//        $trigger.addClass("focused");
//        var name = $trigger.data("evtname") || $trigger.attr("name");
//        ASAPP.GA.send("ApplyForm", "Focus", name);
//    }
//};

//ASAPP.GA.send = function (category, action, label, value) {
//    var event = {
//        "hitType": "event",
//        "eventCategory": category,
//        "eventAction": action
//    };
//    if (label) {
//        event.eventLabel = label;
//    }
//    if (value) {
//        value.eventValue = label;
//    }
//    ga(ASAPP.GA.baseNamespace + ".send", event);
//    if (ASAPP.GA.hasSiteTracking) {
//        ga(ASAPP.GA.siteNamespace + ".send", event);
//    }
//};

//$(document).ready(function () {
//    if (ga) {
//        $(".trackEvent").click(ASAPP.GA.trackEvent);
//        $(".form-control, .trackFocus").focus(ASAPP.GA.trackFocus);
//    }
//});