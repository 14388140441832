// This sample uses the Places Autocomplete widget to:
// 1. Help the user select a place
// 2. Retrieve the address components associated with that place
// 3. Populate the form fields with those address components.
// This sample requires the Places library, Maps JavaScript API.
// Include the libraries=places parameter when you first load the API.
// For example: <script


var lookupField0;
var lookupField1;
var lookupField2;
var lookupField3;
var lookupField4;
var lookupField5;
var lookupField6;
var lookupField7;
var lookupField8;
var lookupField9;



var autogcomplete0;
var autogcomplete1;
var autogcomplete2;
var autogcomplete3;
var autogcomplete4;
var autogcomplete5;
var autogcompleteBeneficiary;
var autogcompleteBeneficiaryWithSuccessor;
var autogcompleteBeneficiaryAlternate;
var autogcompletemortgageAddress;


var address1Field;
var suiteNumberField;
var postalField;
var streetNumberField;
var streetNameField;
var cityField;
var provinceField;
var streetNumberprimary;
var streetNameprimary;
var provinceprimary;
var SelectedCountrycode;



var address1PhysicalField;
var suiteNumberPhysicalField;
var postalPhysicalField;
var streetNumberPhysicalField;
var streetNamePhysicalField;
var cityPhysicalField;
var provincePhysicalField;
var streetNumberPhysical;
var streetNamePhysical;
var provincePhysical;
var SelectedPhysicalCountrycode;


var address1PreviousField
var suiteNumberPreviousField
var postalPreviousField
var streetNumberPreviousField
var streetNamePreviousField
var cityPreviousField
var provincePreviousField
var streetNumberPrevious;
var streetNamePrevious;
var provincePrevious;
var SelectedPreviousCountrycode;


var address1KinField
var suiteNumberKinField
var postalKinField
var streetNumberKinField
var streetNameKinField
var cityKinField
var provinceKinField
var streetNumberKin;
var streetNameKin;
var provinceKin;
var SelectedKinCountrycode;




var address1EmployerField
var suiteNumberEmployerField
var postalEmployerField
var streetNumberEmployerField
var streetNameEmployerField
var cityEmployerField
var provinceEmployerField
var streetNumberEmployer;
var streetNameEmployer;
var provinceEmployer;
var SelectedEmployerCountrycode;



var address1PreviousEmployerField
var suiteNumberPreviousEmployerField
var postalPreviousEmployerField
var streetNumberPreviousEmployerField
var streetNamePreviousEmployerField
var cityPreviousEmployerField
var provincePreviousEmployerField
var streetNumberPreviousEmployer;
var streetNamePreviousEmployer;
var provincePreviousEmployer;
var SelectedPreviousEmployerCountrycode;




var address1BeneficiaryField
var suiteNumberBeneficiaryField
var postalBeneficiaryField
var streetNumberBeneficiaryField
var streetNameBeneficiaryField
var cityBeneficiaryField
var provinceBeneficiaryField
var streetNumberBeneficiary;
var streetNameBeneficiary;
var provinceBeneficiary;
var SelectedBeneficiaryWithCountrycode;


var address1BeneficiaryWithSuccessorField
var suiteNumberBeneficiaryWithSuccessorField
var postalBeneficiaryWithSuccessorField
var streetNumberBeneficiaryWithSuccessorField
var streetNameBeneficiaryWithSuccessorField
var cityBeneficiaryWithSuccessorField
var provinceBeneficiaryWithSuccessorField
var streetNumberBeneficiaryWithSuccessor;
var streetNameBeneficiaryWithSuccessor;
var provinceBeneficiaryWithSuccessor;
var SelectedBeneficiarySuccessorCountrycode;

var address1BeneficiaryAlternateField
var suiteNumberBeneficiaryAlternateField
var postalBeneficiaryAlternateField
var streetNumberBeneficiaryAlternateField
var streetNameBeneficiaryAlternateField
var cityBeneficiaryAlternateField
var provinceBeneficiaryAlternateField
var streetNumberBeneficiaryAlternate;
var streetNameBeneficiaryAlternate;
var provinceBeneficiaryAlternate;
var SelectedBeneficiaryAlternateCountrycode

var address1mortgageAddressField
var suiteNumbermortgageAddressField
var postalmortgageAddressField
var streetNumbermortgageAddressField
var streetNamemortgageAddressField
var citymortgageAddressField
var provincemortgageAddressField
var streetNumbermortgageAddress;
var streetNamemortgageAddress;
var provincemortgageAddress;
var SelectedmortgageAddressCountrycode


var isActivegoogle;


var componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
};

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
    isActivegoogle = true;
    googleautoInitAddressCompleteControls();
}
function employergeolocate() {
    var addressCompleteKey = $("#google-address-complete-key").val();

    // Employer address
    configureGoogleEmployerAddressLookup(addressCompleteKey, 'employer', 'EmploymentInformation_Employer', "EmploymentInformation_EmployerStreetAddress", $("#has-employer-address-populated").val(),
        $('.employer-address-lookup'), $('#employer-address-fields'), true);
    ////Previous Employer address
    configureGooglePreviousEmployerAddressLookup(addressCompleteKey, 'previous-employer', 'EmploymentInformation_PreviousEmployer', "EmploymentInformation_PreviousEmployerStreetAddress", $("#has-previous-employer-address-populated").val(),
        $('.previous-employer-address-lookup'), $('#previous-employer-address-fields'), true);


    $(".manual-address-employer").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'employer');
    });
    $(".manual-address-previous-employer").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'previous-employer');
    });
}
function Physicalgeolocate() {
    var addressCompleteKey = $("#google-address-complete-key").val();
    // Physical address
    configureGooglePhysicalAddressLookup(addressCompleteKey, 'physical', 'MailingAddress_Physical', "MailingAddress_PhysicalStreetAddress", $("#has-physical-address-populated").val(),
        $("#PhysicalAddressLookup").closest(".address-lookup"), $("#physical-address-fields"), true);
    $(".manual-address-physical").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'physical');
    });
    $(".different-address-yes").on('click', function () {
        showPhysicalAddressLookup();
    });

    $(".different-address-no").on('click', function () {
        $("#physical-address-fields").hide();
        $("#physical-address").slideUp();
    }); loadBankingAddressSection('MailingAddress_Physical');
    $('.physical-rural-address input:checked').each(function () { setRuralAddress($(this)); });

    $('.physical-rural-address input').change(function () {
        setRuralAddress($(this));
    });
    $("#physical-address-replace").click(function () {
        populateAddressWithInfoFromBanking("MailingAddress_Physical");
    });
}
function Previousgeolocate() {
    var addressCompleteKey = $("#google-address-complete-key").val();
    // Previous address
    configureGooglePreviousAddressLookup(addressCompleteKey, 'previous', 'MailingAddress_Previous', "MailingAddress_PreviousStreetAddress", $("#has-previous-address-populated").val(),
        $("#PreviousAddressLookup").closest(".address-lookup"), $("#previous-address-fields"), true);

    $(".manual-address-previous").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'previous');
    });
    $(".manual-address-previous-employer").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'previous-employer');
    });
    $(".two-years-address-no").on('click', function () {
        $("#PreviousAddressLookup").val('');
        $("#PreviousAddressLookup").closest(".mdl-textfield").removeClass('is-dirty');
        $("#previous-address-lookup").removeClass("hidden");
        $("#previous-address").slideDown();
    });
    $(".two-years-address-yes").on('click', function () {
        $("#previous-address-fields").hide();
        $("#previous-address").slideUp();
    });
    loadBankingAddressSection('MailingAddress_Previous');
    $('.previous-rural-address input:checked').each(function () { setRuralAddress($(this)); });

    $('.previous-rural-address input').change(function () {
        setRuralAddress($(this));
    });
    $("#previous-address-replace").click(function () {
        populateAddressWithInfoFromBanking("MailingAddress_Previous");
    });
}
function AddressAutoCompleteViewModel() {
    configureGoogleAddressViewModelLookup('', 'primary', 'AddressAutoCompleteViewModel_', "AddressAutoCompleteViewModel_StreetAddress", $("#has-address-populated").val(),
        $("#AddressLookup").closest(".address-lookup"), $("#address-information"), true);
}
function Kingeolocate() {
    var addressCompleteKey = $("#google-address-complete-key").val();
    // Kin address
    configureGoogleKinAddressLookup(addressCompleteKey, 'kin', 'EmploymentInformation_Kin', "EmploymentInformation_KinStreetAddress", $("#has-kin-address-populated").val(), $('.kin-address-lookup'),
        $('#kin-address-fields'), true);

    $(".manual-address-kin").on("click", function (e) {
        e.preventDefault();
        showAddressSection(null, 'kin');
    });

}


function googleautoInitAddressCompleteControls() {

    if (isActivegoogle == true) {
        if ($("#google-address-complete-enabled").val() === "true") {
            var addressCompleteKey = $("#google-address-complete-key").val();
            var isBusiness = $("#isBusiness").length;

            // Primary address
            configureGoogleAddressLookup(addressCompleteKey, 'primary', 'MailingAddress_', isBusiness ? "StreetName" : "MailingAddress_StreetAddress", $("#has-address-populated").val(),
                $("#AddressLookup").closest(".address-lookup"), $("#primary-address-fields"), true);

            $(".manual-address-private").on("click", function (e) {
                e.preventDefault();
                showAddressSection(null, 'primary');
            });

            loadBankingAddressSection('MailingAddress');



            //$('.po-box input:checked').each(function () { setPOBox($(this)); });

            $('.po-box input').change(function () {
                setPOBox($(this));
            });

            $('.rural-address input:checked').each(function () { setRuralAddress($(this)); });

            $('.rural-address input').change(function () {
                setRuralAddress($(this));
            });




            $("#mailing-address-replace").click(function () {
                populateAddressWithInfoFromBanking("MailingAddress");
            });


        }
    }
}





function setupGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1Field = document.querySelector("#" + lookupField);
    lookupField0 = lookupField
    suiteNumberField = document.querySelector("#" + suiteNumber);
    postalField = document.querySelector("#" + postalCode);
    streetNumberField = document.querySelector("#" + streetNumber);
    streetNameField = document.querySelector("#" + streetName);
    cityField = document.querySelector("#" + city);
    provinceField = document.querySelector("#" + province);
    streetNumberprimary = streetNumber; streetNameprimary = streetName; provinceprimary = province;

    if (address1Field != null) {
        if ($('#Country').length) {
            SelectedCountrycode = $('#Country').val();
        }

        autogcomplete0 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete0.addListener('place_changed', fillInAddress);
    }

}
function fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete0.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1Field.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //} 
           

            case "locality":
                cityField.value = component.long_name;
                address.City = component.long_name;
                break;

            case "administrative_area_level_1": {
                provinceField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }


    streetNumberField.value = address.BuildingNumber;
    postalField.value = postcode;
    address.PostalCode = postcode;
    $("#MailingAddress_AddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#MailingAddress_AddressLine2").val('');
    $("#MailingAddress_State").val(address.ProvinceName);


    $('#MailingAddress_Lookup').addClass('hidden');

    $("#primary-address-fields").removeClass('hidden');
    $("#primary-address-fields").show();


    showAddressSection(address, 'primary');
    configureGoogleSet(address, lookupField0, provinceprimary, streetNumberprimary, streetNameprimary);
}


function setupPhysicalGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1PhysicalField = document.querySelector("#" + lookupField);
    lookupField1 = lookupField
    suiteNumberPhysicalField = document.querySelector("#" + suiteNumber);
    postalPhysicalField = document.querySelector("#" + postalCode);
    streetNumberPhysicalField = document.querySelector("#" + streetNumber);
    streetNamePhysicalField = document.querySelector("#" + streetName);
    cityPhysicalField = document.querySelector("#" + city);
    provincePhysicalField = document.querySelector("#" + province);
    streetNumberPhysical = streetNumber; streetNamePhysical = streetName; provincePhysical = province;


    if (address1PhysicalField != null) {
        if ($('#PhysicalCountry').length) {
            SelectedPhysicalCountrycode = $('#PhysicalCountry').val();
        }
        autogcomplete1 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedPhysicalCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete1.addListener('place_changed', fillInAddressPhysical);
    }

}
function fillInAddressPhysical() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete1.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1PhysicalField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNamePhysicalField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

            

            case "locality":
                cityPhysicalField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provincePhysicalField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                address.Circle = component.short_name;

                break;
        }
    }

    streetNumberPhysicalField.value = address.BuildingNumber;
    postalPhysicalField.value = postcode;
    address.PostalCode = postcode;

    $("#MailingAddress_PhysicalAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#MailingAddress_PhysicalAddressLine2").val('');
    $("#MailingAddress_PhysicalState").val(address.ProvinceName);



    $('#MailingAddress_Physical_Lookup').addClass('hidden');

    $("#physical-address-fields").removeClass('hidden');
    $("#physical-address-fields").show();

    showAddressSection(address, 'physical');
    configureGoogleSet(address, lookupField1, provincePhysical, streetNumberPhysical, streetNamePhysical);



}


function setupPreviousGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1PreviousField = document.querySelector("#" + lookupField);
    lookupField2 = lookupField
    suiteNumberPreviousField = document.querySelector("#" + suiteNumber);
    postalPreviousField = document.querySelector("#" + postalCode);
    streetNumberPreviousField = document.querySelector("#" + streetNumber);
    streetNamePreviousField = document.querySelector("#" + streetName);
    cityPreviousField = document.querySelector("#" + city);
    provincePreviousField = document.querySelector("#" + province);

    streetNumberPrevious = streetNumber; streetNamePrevious = streetName; provincePrevious = province;
    if (address1PreviousField != null) {
        if ($('#PreviousCountry').length) {
            SelectedPreviousCountrycode = $('#PreviousCountry').val();
        }


        autogcomplete2 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedPreviousCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete2.addListener('place_changed', fillInAddressPrevious);
    }

}
function fillInAddressPrevious() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete2.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1PreviousField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNamePreviousField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }
            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

            
            case "locality":
                cityPreviousField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provincePreviousField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }


    streetNumberPreviousField.value = address.BuildingNumber;
    postalPreviousField.value = postcode;
    address.PostalCode = postcode;
    $("#MailingAddress_PreviousAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#MailingAddress_PreviousAddressLine2").val('');
    $("#MailingAddress_PreviousState").val(address.ProvinceName);


    $('#MailingAddress_Previous_Lookup').addClass('hidden');

    $("#Previous-address-fields").removeClass('hidden');
    $("#Previous-address-fields").show();


    showAddressSection(address, 'Previous');

    configureGoogleSet(address, lookupField2, provincePrevious, streetNumberPrevious, streetNamePrevious);




}



function setupKinGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1KinField = document.querySelector("#" + lookupField);
    lookupField3 = lookupField
    suiteNumberKinField = document.querySelector("#" + suiteNumber);
    postalKinField = document.querySelector("#" + postalCode);
    streetNumberKinField = document.querySelector("#" + streetNumber);
    streetNameKinField = document.querySelector("#" + streetName);
    cityKinField = document.querySelector("#" + city);
    provinceKinField = document.querySelector("#" + province);
    provinceKin = province; streetNumberKin = streetNumber; streetNameKin = streetName;


    if (address1KinField != null) {
        if ($('#KinCountry').length) {
            SelectedKinCountrycode = $('#KinCountry').val();
        }
        autogcomplete3 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedKinCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete3.addListener('place_changed', fillInAddressKin);
    }

}
function fillInAddressKin() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete3.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1KinField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameKinField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

           
            case "locality":
                cityKinField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provinceKinField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }


    streetNumberKinField.value = address.BuildingNumber;
    postalKinField.value = postcode;
    $("#KinAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#KinAddressLine2").val('');
    $("#KinState").val(address.ProvinceName);

    address.PostalCode = postcode;
    showAddressSection(address, 'kin');
    configureGoogleSet(address, lookupField3, provinceKin, streetNumberKin, streetNameKin);





}


function setupEmployerGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1EmployerField = document.querySelector("#" + lookupField);
    lookupField4 = lookupField
    suiteNumberEmployerField = document.querySelector("#" + suiteNumber);
    postalEmployerField = document.querySelector("#" + postalCode);
    streetNumberEmployerField = document.querySelector("#" + streetNumber);
    streetNameEmployerField = document.querySelector("#" + streetName);
    cityEmployerField = document.querySelector("#" + city);
    provinceEmployerField = document.querySelector("#" + province);
    provinceEmployer = province; streetNumberEmployer = streetNumber; streetNameEmployer = streetName;

    if (address1EmployerField != null) {
        if ($('#EmployerCountry').length) {
            SelectedEmployerCountrycode = $('#EmployerCountry').val();
        }
        autogcomplete4 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedEmployerCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete4.addListener('place_changed', fillInAddressEmployer);
    }

}
function fillInAddressEmployer() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete4.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1EmployerField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameEmployerField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }
            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

           
            case "locality":
                cityEmployerField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provinceEmployerField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }

    streetNumberEmployerField.value = address.BuildingNumber;
    postalEmployerField.value = postcode;
    address.PostalCode = postcode;

    $("#EmploymentInformation_EmployerAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#EmploymentInformation_EmployerAddressLine2").val('');
    $("#EmploymentInformation_EmployerState").val(address.ProvinceName);


    showAddressSection(address, 'employer');
    configureGoogleSet(address, lookupField4, provinceEmployer, streetNumberEmployer, streetNameEmployer);





}

function setupPreviousEmployerGoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, isGoogle) {
    address1PreviousEmployerField = document.querySelector("#" + lookupField);
    lookupField5 = lookupField
    suiteNumberPreviousEmployerField = document.querySelector("#" + suiteNumber);
    postalPreviousEmployerField = document.querySelector("#" + postalCode);
    streetNumberPreviousEmployerField = document.querySelector("#" + streetNumber);
    streetNamePreviousEmployerField = document.querySelector("#" + streetName);
    cityPreviousEmployerField = document.querySelector("#" + city);
    provincePreviousEmployerField = document.querySelector("#" + province);
    provincePreviousEmployer = province; streetNumberPreviousEmployer = streetNumber; streetNamePreviousEmployer = streetName;

    if (address1PreviousEmployerField != null) {
        if ($('#PreviousEmployerCountry').length) {
            SelectedPreviousEmployerCountrycode = $('#PreviousEmployerCountry').val();
        }
        autogcomplete5 = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedPreviousEmployerCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete5.addListener('place_changed', fillInAddressPreviousEmployer);
    }

}
function fillInAddressPreviousEmployer() {
    // Get the place details from the autocomplete object.
    var place = autogcomplete5.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1PreviousEmployerField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNamePreviousEmployerField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

           
            case "locality":
                cityPreviousEmployerField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provincePreviousEmployerField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }

    streetNumberPreviousEmployerField.value = address.BuildingNumber;
    postalPreviousEmployerField.value = postcode;
    address.PostalCode = postcode;

    $("#EmploymentInformation_PreviousEmployerAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#EmploymentInformation_PreviousEmployerAddressLine2").val('');
    $("#EmploymentInformation_PreviousEmployerState").val(address.ProvinceName);


    showAddressSection(address, 'previous-employer');

    configureGoogleSet(address, lookupField5, provincePreviousEmployer, streetNumberPreviousEmployer, streetNamePreviousEmployer);




}


function setupBeneficiaryAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, type) {
    address1BeneficiaryField = document.querySelector("#" + lookupField);
    type = type;
    lookupField6 = lookupField
    suiteNumberBeneficiaryField = document.querySelector("#" + suiteNumber);
    postalBeneficiaryField = document.querySelector("#" + postalCode);
    streetNumberBeneficiaryField = document.querySelector("#" + streetNumber);
    streetNameBeneficiaryField = document.querySelector("#" + streetName);
    cityBeneficiaryField = document.querySelector("#" + city);
    provinceBeneficiaryField = document.querySelector("#" + province);

    streetNumberBeneficiary = streetNumber; streetNameBeneficiary = streetName; provinceBeneficiary = province;

    if ($('#primaryCountry').length) {
        SelectedBeneficiaryWithCountrycode = $('#primaryCountry').val();
    }

    if (SelectedBeneficiaryWithCountrycode != "") {
        autogcompleteBeneficiary = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedBeneficiaryWithCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcompleteBeneficiary.addListener('place_changed', fillInAddressBeneficiary);
    }

}
function fillInAddressBeneficiary() {

    // Get the place details from the autocomplete object.
    var place = autogcompleteBeneficiary.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1BeneficiaryField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameBeneficiaryField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

            
            case "locality":
                cityBeneficiaryField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provinceBeneficiaryField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }

    streetNumberBeneficiaryField.value = address.BuildingNumber;
    postalBeneficiaryField.value = postcode;
    address.PostalCode = postcode;
    $("#primaryAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#primaryAddressLine2").val('');
    $("#primaryState").val(address.ProvinceName);




    showGoogleAddressSection("manual", 'primary-ben-add-form');

    configureGoogleSet(address, lookupField6, provinceBeneficiary, streetNumberBeneficiary, streetNameBeneficiary);




}

function setupBeneficiarywithsuccessorAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, type) {
    address1withsuccessorBeneficiaryWithSuccessorField = document.querySelector("#" + lookupField);
    type = type;
    lookupField7 = lookupField
    suiteNumberBeneficiaryWithSuccessorField = document.querySelector("#" + suiteNumber);
    postalBeneficiaryWithSuccessorField = document.querySelector("#" + postalCode);
    streetNumberBeneficiaryWithSuccessorField = document.querySelector("#" + streetNumber);
    streetNameBeneficiaryWithSuccessorField = document.querySelector("#" + streetName);
    cityBeneficiaryWithSuccessorField = document.querySelector("#" + city);
    provinceBeneficiaryWithSuccessorField = document.querySelector("#" + province);

    streetNumberBeneficiaryWithSuccessor = streetNumberBeneficiaryWithSuccessor; streetNameBeneficiaryWithSuccessor = streetName; provinceBeneficiaryWithSuccessor = province;
    if ($('#withsuccessorCountry').length) {
        SelectedBeneficiarySuccessorCountrycode = $('#withsuccessorCountry').val();
    }
    if (SelectedBeneficiarySuccessorCountrycode != "") {
        autogcomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedBeneficiarySuccessorCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcomplete.addListener('place_changed', fillInAddressBeneficiarywithsuccessor);
    }

}
function fillInAddressBeneficiarywithsuccessor() {
    // Get the place details from the autocomplete object.
    var place = autogcompleteBeneficiaryWithSuccessor.getPlace();
    var address = {
        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1BeneficiaryWithSuccessorField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameBeneficiaryWithSuccessorField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }
            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}

           
            case "locality":
                cityBeneficiaryWithSuccessorField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provinceBeneficiaryWithSuccessorField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;
                break;
        }
    }

    streetNumberBeneficiaryWithSuccessorField.value = address.BuildingNumber;
    postalBeneficiaryWithSuccessorField.value = postcode;
    address.PostalCode = postcode;
    $("#withsuccessorAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#withsuccessorAddressLine2").val('');
    $("#withsuccessorState").val(address.ProvinceName);




    showGoogleAddressSection("manual", 'withsuccessor-ben-add-form');

    configureGoogleSet(address, lookupField7, provinceBeneficiaryWithSuccessor, streetNumberBeneficiaryWithSuccessor, streetNameBeneficiaryWithSuccessor);




}

function setupBeneficiaryalternategoogleAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, type) {
    address1BeneficiaryAlternateField = document.querySelector("#" + lookupField);
    type = type;
    lookupField8 = lookupField
    suiteNumberBeneficiaryAlternateField = document.querySelector("#" + suiteNumber);
    postalBeneficiaryAlternateField = document.querySelector("#" + postalCode);
    streetNumberBeneficiaryAlternateField = document.querySelector("#" + streetNumber);
    streetNameBeneficiaryAlternateField = document.querySelector("#" + streetName);
    cityBeneficiaryAlternateField = document.querySelector("#" + city);
    provinceBeneficiaryAlternateField = document.querySelector("#" + province);

    streetNumberBeneficiaryAlternate = streetNumber; streetNameBeneficiaryAlternate = streetName; provinceBeneficiaryAlternate = province;
    if ($('#alternateCountry').length) {
        SelectedBeneficiaryAlternateCountrycode = $('#alternateCountry').val();
    }

    if (SelectedBeneficiaryAlternateCountrycode != "") {
        autogcompleteBeneficiaryAlternate = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
            { componentRestrictions: { country: [SelectedBeneficiaryAlternateCountrycode] }, types: ['address'] });
        //address1Field.focus();
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autogcompleteBeneficiaryAlternate.addListener('place_changed', fillInAddressBeneficiaryalternate);
    }

}
function fillInAddressBeneficiaryalternate() {


    // Get the place details from the autocomplete object.
    var place = autogcompleteBeneficiaryAlternate.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1BeneficiaryAlternateField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNameBeneficiaryAlternateField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}
            
            case "locality":
                cityBeneficiaryAlternateField.value = component.long_name;
                address.City = component.long_name;
                break;
            case "administrative_area_level_1": {
                provinceBeneficiaryAlternateField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }

    streetNumberBeneficiaryAlternateField.value = address.BuildingNumber;
    postalBeneficiaryAlternateField.value = postcode;
    address.PostalCode = postcode;
    $("#alternateAddressLine1").val(FormatingAddress(address.BuildingNumber, street));
    $("#alternateAddressLine2").val('');
    $("#alternateState").val(address.ProvinceName);





    showGoogleAddressSection("manual", 'alternate-ben-add-form');

    configureGoogleSet(address, lookupField8, provinceBeneficiaryAlternate, streetNumberBeneficiaryAlternate, streetNameBeneficiaryAlternate);




}




function setupmortgageAddressAddressComplete(key, lookupField, suiteNumber, streetNumber, streetName, city, province, postalCode, type) {
    address1mortgageAddressField = document.querySelector("#" + lookupField);
    type = type;
    lookupField9 = lookupField
    suiteNumbermortgageAddressField = document.querySelector("#" + suiteNumber);
    postalmortgageAddressField = document.querySelector("#" + postalCode);
    streetNumbermortgageAddressField = document.querySelector("#" + streetNumber);
    streetNamemortgageAddressField = document.querySelector("#" + streetName);
    citymortgageAddressField = document.querySelector("#" + city);
    provincemortgageAddressField = document.querySelector("#" + province);

    streetNumbermortgageAddress = streetNumber; streetNamemortgageAddress = streetName; provincemortgageAddress = province;
    if ($('#MortgageCountry').length) {
        SelectedmortgageAddressCountrycode = $('#MortgageCountry').val();
    }


    autogcompletemortgageAddress = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(lookupField)),
        { componentRestrictions: { country: [SelectedmortgageAddressCountrycode] }, types: ['address'] });
    //address1Field.focus();
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autogcompletemortgageAddress.addListener('place_changed', fillInAddressmortgageAddress);

}
function fillInAddressmortgageAddress() {


    // Get the place details from the autocomplete object.
    var place = autogcompletemortgageAddress.getPlace();
    var address = {

        BuildingNumber: "",
        City: "",
        Company: "",
        CountryIso2: "",
        CountryIso3: "",
        CountryIsoNumber: 0,
        CountryName: "",
        DataLevel: "Premise",
        DomesticId: "",
        FormattedLine1: "",
        Id: "",
        Label: "",
        Language: "ENG",
        LanguageAlternatives: "ENG,FRE",
        Line1: "",
        POBoxNumber: "",
        PostalCode: "",
        Province: "",
        ProvinceCode: "",
        ProvinceName: "",
        SecondaryStreet: "",
        SortingNumber1: "",
        SortingNumber2: "",
        Street: "",
        SubBuilding: "",
        Type: "Residential"
    }
    address.Label = address1mortgageAddressField.value;
    var address1 = "";
    var street = "";
    var postcode = "";

    for (var component of place.address_components) {
        // @ts-ignore remove once typings fixed
        var componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.BuildingNumber = component.long_name;
                break;
            }

            case "route": {
                street = component.short_name;
                address.FormattedLine1 = component.long_name;
                address.Street = street;
                streetNamemortgageAddressField.value = component.short_name;
                break;
            }

            case "postal_code": {
                postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                postcode = postcode;
                break;
            }

            //case "postal_code_prefix": {
            //    postcode = component.long_name;
            //    break;
            //}
            
            case "locality":
                citymortgageAddressField.value = component.long_name;
                address.City = component.long_name;
                break;

            case "administrative_area_level_1": {
                provincemortgageAddressField.value = component.long_name;
                address.ProvinceName = component.long_name;
                address.ProvinceCode = component.short_name;
                break;
            }
            case "country":
                address.CountryIso2 = component.long_name;
                //address.Circle = component.short_name;

                break;
        }
    }

    streetNumbermortgageAddressField.value = address.BuildingNumber;
    postalmortgageAddressField.value = postcode;
    address.PostalCode = postcode;
    $("#AddressAutoCompleteViewModel_MortgageAddressline1").val(FormatingAddress(address.BuildingNumber, street));
    $("#AddressAutoCompleteViewModel_MortgageAddressline2").val('');
    $("#AddressAutoCompleteViewModel_MortgageState").val(address.ProvinceName);


    $(".address-information").removeClass("hidden");
    $(".address-lookup").addClass("hidden");
    showAddressSectiong('manual', $(".address-information"));

    configureGoogleSet(address, lookupField9, provincemortgageAddress, streetNumbermortgageAddress, streetNamemortgageAddress);

}

function showAddressSectiong(type, offlineAddress) {
    if (type === "auto") {
        offlineAddress.hide();
        offlineAddress.prev().show();
    } else if (type === "manual") {
        offlineAddress.show();

        if (offlineAddress.find('[name="AddressAutoCompleteViewModel.SuiteNumber"]').val() !== '')
            offlineAddress.find('[name="AddressAutoCompleteViewModel.SuiteNumber"]').closest(".mdl-textfield").addClass('is-dirty');
        if (offlineAddress.find('[name="AddressAutoCompleteViewModel.StreetNumber"]').val() !== '')
            offlineAddress.find('[name="AddressAutoCompleteViewModel.StreetNumber"]').closest(".mdl-textfield").addClass('is-dirty');
        if (offlineAddress.find('[name="AddressAutoCompleteViewModel.StreetAddress"]').val() !== '')
            offlineAddress.find('[name="AddressAutoCompleteViewModel.StreetAddress"]').closest(".mdl-textfield").addClass('is-dirty');
        if (offlineAddress.find('[name="AddressAutoCompleteViewModel.City"]').val() !== '')
            offlineAddress.find('[name="AddressAutoCompleteViewModel.City"]').closest(".mdl-textfield").addClass('is-dirty');
        if (offlineAddress.find('[name="AddressAutoCompleteViewModel.Postal"]').val() !== '')
            offlineAddress.find('[name="AddressAutoCompleteViewModel.Postal"]').closest(".mdl-textfield").addClass('is-dirty');

        offlineAddress.prev().hide();
    }
}


function configureGoogleAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}
function configureGooglePhysicalAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupPhysicalGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}
function configureGooglePreviousAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupPreviousGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}
function configureGoogleKinAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupKinGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}

function configureGoogleEmployerAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupEmployerGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}
function configureGooglePreviousEmployerAddressLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupPreviousEmployerGoogleAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}




function configureGoogleAddressViewModelLookup(addressCompleteKey, type, fieldPrefix, streetAddressField, addressPopulated, $lookup, $fields, isGoogle) {
    var addressController = setupmortgageAddressAddressComplete(addressCompleteKey, fieldPrefix + "AddressLookup", fieldPrefix + "SuiteNumber",
        fieldPrefix + "StreetNumber", streetAddressField, fieldPrefix + "City", fieldPrefix + "Province", fieldPrefix + "Postal", isGoogle);

    addressCompleteHandler(addressController, function (address) { showAddressSection(address, type); });

    if (addressPopulated === 'true') {
        $lookup.addClass('hidden');
        if (type !== 'employer') {
            $fields.removeClass('hidden');
            $fields.show();
        }
    } else {
        $lookup.removeClass('hidden');
        $fields.addClass('hidden');
    }
}


function configureGoogleSet(address, lookupField, province, streetNumber, streetName) {
    if (lookupField === "primaryAddressLookup"
        || lookupField === "alternateAddressLookup"
        || lookupField === "withsuccessorAddressLookup") {
        $("input[id='" + province + "']").closest(".mdl-select").data("mdl-select").setValue(address.ProvinceCode);
        if (address.POBoxNumber) {
            $("input[id='" + streetNumber + "']").val(address.POBoxNumber);
            var streetNameValue = address.Line1.match(/Stn.*/);
            $("input[id='" + streetName + "']").val(streetNameValue !== null ? streetNameValue[0] : "");
            $("input[id='" + streetName + "']").closest(".offline-address").find("input[name='IsPOBox'][value='True']").click();
        }
        else {
            $("input[id='" + streetName + "']").closest(".offline-address").find("input[name='IsPOBox'][value='False']").click();
        }

    }
    else {

        if (address.POBoxNumber) {
            $("input[id='" + streetNumber + "']").val(address.POBoxNumber);
            var streetNameValue = address.Line1.match(/Stn.*/);
            $("input[id='" + streetName + "']").val(streetNameValue !== null ? streetNameValue[0] : "");
        }
        $("input[id='" + province + "']").closest(".mdl-select").data("mdl-select").setValue(address.ProvinceCode);


    }
}
/*window.initgAutocomplete = initgAutocomplete;*/
function FormatingAddress(BuildingNumber, StreetNumber, PostalCode, Province, Country) {
    var address = "";
    if ($.trim(BuildingNumber).length > 0) {
        address = $.trim(BuildingNumber) + " ";
    }
    if ($.trim(StreetNumber).length > 0) {
        address = address + $.trim(StreetNumber) + " ";
    }
    if ($.trim(PostalCode).length > 0) {
        address = address + $.trim(PostalCode) + " ";
    }
    if ($.trim(Province).length > 0) {
        address = address + $.trim(Province) + " ";
    }
    if ($.trim(Country).length > 0) {
        address = address + $.trim(Country);
    }
    return $.trim(address);
}