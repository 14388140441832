$(document).ready(function () {
        function updateSecuredCount() {
            var securedCount = 0;
            $(".SecuredAssetCheckboxDiv input[type='checkbox']").each(function () {
                if ($(this).is(":checked")) {
                    securedCount++;
                }
            });
            $("#hiddenSecuredAssetInput").val(`SecuredCount = ${securedCount}`);

            // Check if secured count is greater than 0, then enable the button
            if (securedCount > 0) {
                $(".securedAssetNextButton").prop("disabled", false);
            } else {
                $(".securedAssetNextButton").prop("disabled", true);
            }
        }

        // Initial count on page load
        updateSecuredCount();

        // Update count on checkbox change
        $(".SecuredAssetCheckboxDiv input[type='checkbox']").on('change', function () {
            var securedCount = parseInt($("#hiddenSecuredAssetInput").val().split('=')[1]);
            if ($(this).is(":checked")) {
                securedCount++;
            } else {
                securedCount--;
            }
            $("#hiddenSecuredAssetInput").val(`SecuredCount = ${securedCount}`);

            // Check if secured count is greater than 0, then enable the button
            if (securedCount > 0) {
                $(".securedAssetNextButton").prop("disabled", false);
            } else {
                $(".securedAssetNextButton").prop("disabled", true);
            }
        });
});