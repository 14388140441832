function resumeApplicationCallback(data) {
    var $form = $(this);

    if (data) {
        $(".title_h7.text-danger.field-validation-error").remove();

        if (data.Success === true) {
            window.location.href = data.Url;
        } else {
            if (Array.isArray(data.ValidationErrors)) {
                for (var i = 0; i < data.ValidationErrors.length; i++) {
                    var message = document.createElement("span");

                    $(message).addClass("title_h7 text-danger field-validation-error");
                    message.innerHTML = data.ValidationErrors[i];
                    $form.prepend(message);
                }
            }
        }
    }
}